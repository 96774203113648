import styled from 'styled-components'
import { FaExchangeAlt, FaChartLine, FaColumns } from 'react-icons/fa'
import { GiCardExchange } from 'react-icons/gi'
import { useMainContext } from '@/core/contexts/main'
import { getMainTheme } from '@/core/utils/theme'
import { useRouter } from 'next/router'
import Text from '@/components/common/utils/Text'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import { HOME } from '@/core/constants/urls'

const BottomBar = () => {
    const { theme } = useMainContext()
    const { locale: lang, pathname } = useRouter()
    const [active, setActive] = useState(0)
    const [currentTab, setCurrentTab] = useState(null)

    const onTabClicked = (tabName) => {
        // setActive(idx)

        setCurrentTab(tabName)
    }

    console.log(pathname)

    useEffect(() => {
        switch (pathname) {
            case '/':
                setCurrentTab('home-page')
                break
            case '/market':
                setCurrentTab('markets')
                break
            case '/[coin]':
                setCurrentTab('otc-trade')
                break
            default:
                setCurrentTab('p2p-trade')
                break
        }
    }, [pathname])

    const renderedLinks = tabs(lang).map((tab, idx) => {
        if (!!tab.external)
            return (
                <a href={HOME + tab.href} locale={'fa'} key={tab.name}>
                    <TabItem onClick={() => onTabClicked(tab.name)}>
                        <tab.icon size={18} active={tab.name === currentTab} />
                        <DText
                            color={
                                currentTab === tab.name
                                    ? 'rgb(39, 163, 220)'
                                    : ''
                            }>
                            <Text tid={tab.name} />
                        </DText>
                    </TabItem>
                </a>
            )

        return (
            <Link href={tab.href} locale={lang} key={tab.name}>
                <TabItem onClick={() => onTabClicked(tab.name)}>
                    <tab.icon size={18} active={tab.name === currentTab} />
                    <DText
                        color={
                            currentTab === tab.name ? 'rgb(39, 163, 220)' : ''
                        }>
                        <Text tid={tab.name} />
                    </DText>
                </TabItem>
            </Link>
        )
    })

    return (
        <Body>
            <BottomBarWrapper>{renderedLinks}</BottomBarWrapper>
        </Body>
    )
}

const DashboardIcon = styled(FaColumns)`
    color: ${(props) =>
        props.active ? 'rgb(39, 163, 220)' : props.theme.color};
`
const MarketsIcon = styled(FaChartLine)`
    color: ${(props) =>
        props.active ? 'rgb(39, 163, 220)' : props.theme.color};
`
const OtcIcon = styled(FaExchangeAlt)`
    color: ${(props) =>
        props.active ? 'rgb(39, 163, 220)' : props.theme.color};
`
const WalletIcon = styled(GiCardExchange)`
    color: ${(props) =>
        props.active ? 'rgb(39, 163, 220)' : props.theme.color};
`

const tabs = (lang) => [
    { name: 'home-page', icon: DashboardIcon, href: '/' },
    { name: 'otc-trade', icon: OtcIcon, href: '/btc' },
    { name: 'markets', icon: MarketsIcon, href: '/market' },
    { name: 'p2p-trade', icon: WalletIcon, href: '/trade/', external: true },
]

const Body = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    direction: ${(props) => (props.theme.english ? 'ltr' : 'rtl')};
    background-color: ${(props) => props.theme.primaryBg};
    box-shadow: -2px 0 4px ${(props) => props.theme.mainBg};
    padding: 10px 30px;
    z-index: 99999;
`

const BottomBarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) =>
        props.active ? props.theme.mainOrange : props.theme.color};
`

const DText = styled.div`
    font-size: 1rem;
    color: ${(props) => props.color || props.theme.color};
    font-weight: 600;
`

const TabItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 40px;
`

export default BottomBar
