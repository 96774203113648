import { useMutation, useQuery } from 'react-query'
import { useMainContext } from '@/core/contexts/main'
import { normalFetchWithToken } from '../../fetch-api/get'
import { putApiWithToken } from '../../fetch-api/put'

/**
 * All notification Get api
 * @param page - pagination param
 * @returns {UseQuery}
 */
const useNotificationsQuery = (page) => {
    const { token } = useMainContext()

    return useQuery(
        'fetch-notifications',
        () =>
            normalFetchWithToken(token, `notifications?page=${page}&limit=10`),
        {
            enabled: !!token,
            refetchOnWindowFocus: false,
            select: (res) => res.data,
        }
    )
}

/**
 * Unread notification Get api
 * @param page - pagination param
 * @returns {UseQuery}
 */
const useUnreadNotificationQuery = (page) => {
    const { token } = useMainContext()

    return useQuery(
        'fetch-unread-notifications',
        () =>
            normalFetchWithToken(
                token,
                `notifications/unread?page=${page}&limit=5`
            ),
        {
            enabled: !!token,
            refetchOnWindowFocus: false,
            select: (res) => res.data,
        }
    )
}

/**
 * Read notification Post api
 * get notification id in mutate function
 * @returns {UseMutation}
 */
const useReadNotificationMutation = (callback) => {
    const { token } = useMainContext()

    return useMutation(
        (id) => putApiWithToken({}, token, `notifications/read/${id}`),
        {
            onSuccess: () => {
                callback && callback()
            },
        }
    )
}

export {
    useNotificationsQuery,
    useUnreadNotificationQuery,
    useReadNotificationMutation,
}
