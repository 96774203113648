/**
 *
 * @param {string} theme
 * @param {string} lang
 * @return {Object}
 */
const getMainTheme = (theme = 'light', lang = 'fa') => {
    const MainTheme = {
        light: {
            mainBg: '#ffffff',
            primaryBg: '#162E96',
            secondaryBg: '#1E429F',
            tertionaryBg: '#1A56DB',
            quickTradeBtn: '#1C39BB',
            landingMarketBg: '#1C39BB',
            mainBtnBg: '#1C39BB',
            otcbBoxBg: '#fff',
            menuBg: '#162E96',
            marketBtnHover: '#3e5ce3',
            heroInputBtnBg:
                'linear-gradient(251.3deg, #1E429F 37.36%, #1C64F2 91.17%)',
            appsDownloadBg: '#F3F4F6',
            accordionBg: '#fff',
            color: '#fff',
            profileColor: '#191c20',
            gray: '#111928',
            gray700: '#374151',
            gray50: '#F9FAFB',
            indigo: '#081138',
            indigo400: '#4961C9',
            primary: '#595e67',
            secondary: '#595e67',
            pColor: '#6B7280',
            pColor2: '#848484',
            aboutBtn: '#111928',
            activeAccordion: '#1C39BB',
            accordionHeader: '#374151',
            guideHeading: '#1C39BB',
            getStartedSpan: '#76A9FA',
            hover: '#00000040',
            border: '#9ed4ed',
            borderSecondary: '#E5E7EB',
            WhyBoxBorder: 'inherit',
            appsDownloadBorder: 'transparent',
            accorionBorder: 'transparent',
            guideBorder: 'transparent',
            miniHeading: '#154A94',
            heading: '#374151',

            // --------------------------------------------------------------------------------
            active: '#42958D',
            exitRed: '#a12356',
            mainOrange: '#098091',
            mainRed: '#d54a61',
            mainGreen: '#008e47',
            search: '#f0f1f2',
            input: '#ececec',
            textArea: '#f0f1f2',
            dropdown: '#154A94',
            tradeBg: '#ffffff',
            otcInput: '#f0f1f2',
            black: '#000000',
            orderHover: '#5e6673',
            tInputBg: '#f0f1f2',
            profileTitle: '#50514c',
            headerBg: '#fff',
            headerShadow: '#ccc',
            textColor: '#061222',
            main: '#1099f9',
            mChart: 'wheat',
            inActive: '#72747830',
            kayaHover: '#00000010',
            colorHeaderIconHover: '#00000019',
            settingBg: '#eaeaea',
            buttonHover: '#ffae00',
            // hover: 'rgba(255 ,255 ,255 , 0.1)',
        },
        dark: {
            secondaryBg: '#1E429F',
            tertionaryBg: '#1A56DB',
            landingMarketBg: '#1C39BB',
            quickTradeBtn: '#1C39BB',
            mainBtnBg: '#1C39BB',
            otcbBoxBg: '#161a1e',
            menuBg: '#0C1B36',
            marketBtnHover: '#3e5ce3',
            heroInputBtnBg:
                'linear-gradient(251.3deg, #1E429F 37.36%, #1C64F2 91.17%)',
            accordionBg: 'transparent',
            // appsDownloadBg: '#F3F4F6',
            miniHeading: '#04A996',
            color: '#fff',
            profileColor: '#c3c5b7',
            pColor: '#6B7280',
            pColor2: '#848484',
            aboutBtn: '#9ed4ed',
            activeAccordion: '#1C39BB',
            accordionHeader: '#fff',
            guideHeading: '#1C39BB',
            getStartedSpan: '#76A9FA',
            gray: '#111928',
            border: '#9ed4ed',
            borderSecondary: '#E5E7EB',
            WhyBoxBorder: '#9ed4ed',
            appsDownloadBorder: '#9ed4ed',
            accorionBorder: '#9ed4ed',
            guideBorder: '#9ed4ed',

            //
            mainBg: '#191c20',
            // secondaryBg: '#1b1e23',
            primaryBg: '#161a1e',
            // color: '#c3c5b7',
            secondary: '#eaecef',
            primary: 'white',
            hover: '#26282b70',
            active: '#42958D',
            exitRed: '#a12356',
            mainOrange: '#098091',
            mainRed: '#e9106c',
            mainGreen: '#1ce087',
            search: '#161a1e',
            input: '#2a2d35',
            textArea: '#44464c',
            dropdown: '#36383C',
            black: '#000000',
            tInputBg: '#2a2d35',
            profileTitle: '#ffc800',
            headerBg: '#191c20',
            textColor: '#fafafa',
            main: '#ffc800',
            tradeBg: '#1e2026',
            otcInput: '#0e111a',
            mChart: '#131722',
            orderHover: '#5e6673',
            inActive: '#72747830',
            kayaHover: '#ffffff10',
            colorHeaderIconHover: '#FFFFFF19',
            headerShadow: '#00000029',
            settingBg: 'rgb(43, 49, 57)',
            buttonHover: '#ffae00',
            // hover: 'rgba(0 ,0 ,0 , 0.1)',
        },
    }

    const chosenTheme = { ...MainTheme[theme] }
    chosenTheme.english = lang === 'en'
    return chosenTheme
}

const fadeVariants = {
    out: {
        opacity: 0,
        transition: { duration: 0.3 },
    },
    in: {
        opacity: 1,
        transition: { duration: 0.3 },
    },
}

const getRandomColor = (idx) => {
    const randomColor = ['#818F9F', '#9F819E', '#9F9181', '#819F82']
    return randomColor[idx % 3]
}

export { getMainTheme, fadeVariants, getRandomColor }
