import { createContext, useContext } from 'react'
import {useGetProfile, useLogout} from "@/core/services/react-query/auth";
import {useMainContext} from "@/core/contexts/main";

const AuthContext = createContext()

export const useAuthContext = () => {
    return useContext(AuthContext)
}

export const AuthContextProvider = ({ children }) => {

    const { setToken } = useMainContext()
    const { data: profile, refetch: refetchProfile } = useGetProfile()

    const onLogout = () => {
        setToken('')
    }
    const { mutate: logout, isLoading: loading } = useLogout(onLogout)

    return <AuthContext.Provider value={{ logout, loading, profile, refetchProfile }}>{children}</AuthContext.Provider>
}
