import styled, { css } from 'styled-components'

export const MainButton = styled.button`
    ${(props) =>
        props.link
            ? css`
                  & > a {
                      display: block;
                      padding: ${(props) => props.padding || '1rem 2rem'};
                  }
              `
            : css`
                  padding: ${(props) => props.padding || '1rem 2rem'};
              `}

    background: ${(props) => props.theme.mainBtnBg};
    border-radius: 8px;
`
