import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'

export default function CustomUnderline({
    children,
    width = '100%',
    origin = 'start',
    ...rest
}) {
    const { locale } = useRouter()

    return (
        <Underline width={width} origin={origin} $lang={locale} {...rest}>
            {children}
        </Underline>
    )
}

const Underline = styled.div`
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        z-index: 1;
        display: block;
        width: ${(props) => props.width};
        height: 6px;
        background-image: url('/assets/images/underline.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: bottom;

        ${(props) =>
            props.origin === 'center'
                ? css`
                      right: 50%;
                      transform: translateX(50%);
                  `
                : css`
                      right: 0;
                  `}
    }
`
