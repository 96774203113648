import styled, { css } from 'styled-components'
import { IoIosClose } from 'react-icons/io'

const fontSizes = {
    fb: { d: '2rem', t: '1.4rem', m: '1.2rem' },
    sb: { d: '1.4rem', t: '1.2rem', m: '1rem' },
    b: { d: '1rem', t: '1rem', m: '0.85rem' },
    m: { d: '0.9rem', t: '0.8rem', m: '0.65rem' },
    s: { d: '0.8rem', t: '0.6rem', m: '0.55rem' },
    ss: { d: '0.6rem', t: '0.6rem', m: '0.6rem' },
}

export const MainBody = styled.div`
    min-height: calc(100vh - 80px);
    width: 100%;
    margin: 0 auto 0 auto;
    /* padding: 0 25px; */
`
export const LineBreak = styled.div`
    background: linear-gradient(
        45deg,
        ${(props) => props.theme.color}20 0%,
        ${(props) => props.theme.active}20
    );
    width: 100%;
    height: 1px;
    border-radius: 8px;
    box-shadow: 4px 4px 10px rgb(0, 0, 0, 0.1);
`

export const Flex = styled.div`
    display: flex;
    width: ${(props) =>
        props.width ? props.width : props.fw ? '100%' : 'unset'};
    height: ${(props) =>
        props.height ? props.height : props.fh ? '100%' : 'unset'};
    justify-content: ${(props) => props.justify || 'center'};
    align-items: ${(props) => props.align || 'center'};
    flex-wrap: ${(props) => props.wrap && 'wrap'};
    position: relative;
    margin: ${(props) => props.margin && props.margin};
    padding: ${(props) => props.padding && props.padding};
`

export const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || 'fit-content'};
`

export const CFlex = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) =>
        props.width ? props.width : props.fw ? '100%' : 'unset'};
    height: ${(props) =>
        props.height ? props.height : props.fh ? '100%' : 'unset'};
    justify-content: ${(props) => props.justify || 'center'};
    align-items: ${(props) => props.align || 'center'};
    flex-wrap: ${(props) => props.wrap && 'wrap'};
    position: relative;
    margin: ${(props) => props.margin && props.margin};
    padding: ${(props) => props.padding && props.padding};
`

export const Grid = styled.div`
    display: grid;
    width: ${(props) => props.width || '100%'};
    grid-template-columns: ${(props) => props.gc};
    row-gap: 5px;
`

export const CMargin = styled.div`
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    position: inherit;
    margin: ${(props) => props.margin} 0;
`

export const DText = styled.div`
    font-size: ${({ cFontSize, fontSize }) =>
        cFontSize
            ? cFontSize
            : fontSize
            ? fontSizes[fontSize].d
            : fontSizes.m.d};
    color: ${(props) => props.color && props.color};
    ${(props) =>
        props.main &&
        css`
            color: ${(props) => props.theme.color};
        `};
    ${(props) =>
        props.primary &&
        css`
            color: ${(props) => props.theme.primary};
        `};
    ${(props) =>
        props.active &&
        css`
            color: ${(props) => props.theme.mainOrange};
        `};
    direction: ${(props) => props._type === 'number' && 'ltr'};
    font-family: ${(props) => props.type === 'number' && 'monospace'};
    margin: ${(props) => props.margin && props.margin};

    ${(props) =>
        props.fCenter &&
        css`
            display: flex;
            align-items: center;
            margin-top: auto;
        `}

    @media screen and (max-width: 1050px) {
        font-size: ${({ cFontSize, fontSize }) =>
            cFontSize
                ? cFontSize
                : fontSize
                ? fontSizes[fontSize].d
                : fontSizes.m.t};
    }

    @media screen and (max-width: 768px) {
        font-size: ${({ cFontSize, fontSize }) =>
            cFontSize
                ? cFontSize
                : fontSize
                ? fontSizes[fontSize].d
                : fontSizes.m.m};
    }

    line-break: ${(props) => props.lineBreak || 'auto'};
`

export const IconWrapper = styled.div`
    padding: 4px;
    border-radius: 50px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.color}20;
    }
`

export const CloseIcon = styled(IoIosClose)`
    color: ${(props) => props.theme.primary};
`

export const KarshaOtcBtn = styled.div`
    border-radius: 6px;
    background-color: ${(props) =>
        props.active ? props.theme.active : `${props.theme.inActive}`};
    width: 164px;
    height: 38px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.active ? 'black' : props.theme.color)};
    cursor: pointer;
    box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
    transition: all 0.3s;
    font-size: 0.85rem;
`

export const OtcErrorIconWrapper = styled.div`
    border-radius: 50%;
    outline: 3px solid ${(props) => props.theme.mainOrange};
    padding: 2px;
    box-shadow: 0 0 8px ${(props) => props.theme.mainOrange};
`

export const Decoration = styled.div`
    background-color: ${(props) => props.theme.active};
    width: 12px;
    height: 12px;
    border-radius: 2px;
    transform: rotate(45deg);
`
