import { useEffect, useState } from 'react'
import { io } from 'socket.io-client'

export const useSocket = () => {
    const [socket, setSocket] = useState({})

    useEffect(() => {
        if (!!socket) {
            const socket = io(process.env.NEXT_PUBLIC_SOCKET_ENDPOINT, {
                transports: ['websocket']
            })

            setSocket(socket)
        }

        return () => socket?.close?.()
    }, [])

    return { socket }
}


export const usePricesSocket = () => {
    const [pricesList, setPricesList] = useState([])

    const { socket } = useSocket()

    useEffect(() => {
        console.log('in socket creation')
        socket?.on?.('prices', (data) => {
            setPricesList(
                data.map((item) => {
                    item['changes.24h.changePresent'] = item?.changes?.['24h']?.changePresent || 0

                    return item
                })
            )
        })

        socket?.emit?.('get.prices')

        return () => socket?.off?.('prices')
    }, [socket])

    return { pricesList }
}
