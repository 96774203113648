import {
    FacebookIcon,
    InstagramIcon,
    LinkedInIcon,
    TelegramIcon,
    YoutubeIcon,
} from '@/components/common/icons'
import CustomUnderline from '@/components/common/ui/CustomUnderline'
import Text from '@/components/common/utils/Text'
import { routes } from '@/core/constants/routes'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'
// import Link from 'next/link'
import { BsTwitterX } from 'react-icons/bs'
import { FiMapPin, FiPhone, FiMail } from 'react-icons/fi'

const SOCIALS = [
    {
        icon: <InstagramIcon />,
        route: routes.instagram,
    },
    { icon: <BsTwitterX />, route: routes.twitter },
    { icon: <YoutubeIcon />, route: routes.youtube },
    { icon: <LinkedInIcon />, route: routes.linkedin },
    {
        icon: <TelegramIcon />,
        route: routes.telegram,
    },
]

export const ContactInfo = () => {
    const { width } = useWindowSize()

    const renderedSocials = SOCIALS.map((item, index) => {
        const { icon, route } = item

        return (
            <li
            
                key={index}
                className='transition-transform duration-300 hover:rotate-[360deg]'>
                <a href={route} rel='nofollow'>{icon}</a>
            </li>
        )
    })

    return (
        <div className='mt-5 text-sm'>
            {/* <CustomUnderline width={width > 768 ? '50%' : '20%'}>
                <b className={'text-xl mb-5 block fontBold'}>
                    <Text tid={'footer_contact'} />
                </b>
            </CustomUnderline> */}
            <Text tid={'footer-phrase'} className='text-xs' />
            <div className={'mt-5 flex flex-col gap-6'}>
                {/* <div className='flex text-sm'>
                    <p className='text-sm'>
                        <FiMapPin className='w-6 h-6 me-3 text-footerLinks' />
                    </p>
                    <Text tid={'footer-contact-us-info'} />
                </div> */}
                <div className={'text-start flex'}>
                    <p>
                        <FiPhone className='w-6 h-6 me-3 text-footerLinks' />
                    </p>
                    <a dir={'ltr'} href='tel:02182803055'>02182803055</a>
                </div>
                <div className={'text-start flex'}>
                    <p>
                        <FiMail className='w-6 h-6 me-3 text-footerLinks' />
                    </p>
                    <a dir={'ltr'}>info@tokpenz.com</a>
                </div>

                <div>
                    {/* <CustomUnderline width={width > 768 ? '50%' : '20%'}>
                        <h4 className='mb-5 fontBold'>
                            <Text tid={'FSocial'} />
                        </h4>
                    </CustomUnderline> */}

                    <div>
                        <ul className='flex w-full gap-4 items-center'>
                            {renderedSocials}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
