import { useQuery } from 'react-query'
import { filterFetchWithToken, normalFetchWithToken } from '../../fetch-api/get'
import { useMainContext } from '@/core/contexts/main'

/**
 * Market prices Get api
 * @returns {UseQuery}
 */
const useMarketQuery = () => {
    return useQuery(
        'fetch-market',
        () => normalFetchWithToken(null, 'market'),
        {
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
            select: (res) => res.data?.data,
        }
    )
}

/**
 * Available pairs Get api
 * @returns {UseQuery}
 */
const usePairsQuery = () => {
    return useQuery(
        'fetch-pairs',
        () => normalFetchWithToken(null, 'settings/pairs'),
        {
            select: (res) => res?.data?.data,
            cacheTime: 60 * 60 * 1000,
            staleTime: 60 * 60 * 1000,
            refetchOnWindowFocus: false,
        }
    )
}

const useAvailableCoin = () => {
    return useQuery(
        'fetch-available-coins',
        () => normalFetchWithToken(null, 'settings/coins'),
        {
            select: (res) => res?.data?.data,
            cacheTime: 60 * 60 * 1000,
            staleTime: 60 * 60 * 1000,
            refetchOnWindowFocus: false,
        }
    )
}

const useGetDailyBalance = (params = {}) => {
    const { token } = useMainContext()
    return useQuery(
        'daily-balance',
        () => filterFetchWithToken(params, token, `dailyBalance`),
        {
            enabled: !!token,
            refetchOnWindowFocus: false,
            select: (res) => res.data?.data,
        }
    )
}

const useGetDailyRecords = (params = {}) => {
    const { token } = useMainContext()
    return useQuery(
        'daily-record',
        () => filterFetchWithToken(params, token, `dailyRecords`),
        {
            enabled: !!token,
            refetchOnWindowFocus: false,
            select: (res) => res.data?.data,
        }
    )
}

export {
    useMarketQuery,
    usePairsQuery,
    useAvailableCoin,
    useGetDailyRecords,
    useGetDailyBalance,
}
