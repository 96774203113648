import { useRef, useState } from 'react'
import useClickOutside from '@/core/hooks/common/useClickOutside'
import Text from '@/components/common/utils/Text'
import { IoMdArrowDropdown } from 'react-icons/io'

export default function TopkenzSelect({ title, options, className = '' }) {
    const [open, setOpen] = useState(false)
    const ref = useRef()
    useClickOutside(ref, () => setOpen(false))

    return (
        <div
            className={'relative flex items-center text-base ' + className}
            ref={ref}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}>
            <div
                className={
                    'flex items-center dark:text-gray-200 cursor-pointer'
                }>
                <Text tid={title} />
                <IoMdArrowDropdown
                    size={22}
                    className={'text-white self-end'}
                />
            </div>
            {open ? (
                <div
                    className={`absolute top-[30px] left-0 px-2 
                rounded-md dark:bg-secondaryBg bg-lPrimaryBg shadow-xl py-3
                border-[1px] border-opacity-20 border-gray-500 min-w-[220px]
                flex flex-col
                text-black text-sm`}>
                    {options?.map((item) => {
                        if (item.element) return item.element

                        return (
                            <a
                                className={`flex items-center gap-1 dark:text-gray-200 
                                w-full py-2 px-2 rounded-md cursor-pointer hover:bg-slate-200 dark:hover:bg-slate-400 dark:hover:bg-opacity-20
                                `}
                                key={item.link}
                                href={item.link}>
                                {item.icon}
                                <Text tid={item.tid} className={'text-sm'} />
                            </a>
                        )
                    })}
                </div>
            ) : null}
        </div>
    )
}
