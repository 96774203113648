import axios from "axios"
const BASE_URL = process?.env.BASE_URL


/**
 * Put api without token
 * @param {Object} data
 * @param {string} path
 * @returns {Promise}
 */
const putApi = (data, path) => {
    return axios.put(
        BASE_URL + path, data
    )
}

/**
 * Put api with token
 * @param {Object} data
 * @param {string} token
 * @param {string} path
 * @returns {Promise}
 */
const putApiWithToken = (data, token, path) => {
    return axios.put(
        BASE_URL + path, data, {
        headers: {
            "x-auth-token": token,
        },
    })
}



export {
    putApi,
    putApiWithToken
}
