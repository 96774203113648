import '@/styles/globals.css'
import Providers from '@/components/layout/Providers'
import { getCookie, setCookie } from 'cookies-next'
import MainLayout from '@/components/layout/main/MainLayout'
import { GlobalStyles } from '@/styles/styled/GlobalStyle.styled'
import { useEffect, useState } from 'react'

export default function App({ Component, pageProps, theme, ...rest }) {
    // const [token, setToken] = useState(null);
    // useEffect(() => {
    //     // دریافت توکن از localStorage
    //     const storedToken = localStorage.getItem('token');
    //     if (storedToken) {
    //         setToken(storedToken);
    //     }
    // }, []);


    // TODO: if cookie is empty
    let _theme = theme
    if (!_theme) {
        _theme = 'dark'
        setCookie('theme', _theme)
    }

    return (
        <Providers theme={_theme} {...rest}>
            <MainLayout pageProps={pageProps}>
                <GlobalStyles />
                <Component {...pageProps}/>
            </MainLayout>
        </Providers>
    )
}

App.getInitialProps = ({ ctx }) => {
    const theme = getCookie('theme', ctx)
    const token = getCookie('token', ctx)

    return {
        theme,
        token,
    }
}
