import { forwardRef } from 'react'
import { FaUser, FaCoins, FaHistory, FaUnlockAlt, FaUsers, FaSignOutAlt } from 'react-icons/fa'
import {
	Avatar,
	Balance,
	DropBody,
	Flex,
	FlexColumn,
	Image,
	MOBILE_SIZE,
	MText,
	Profile,
	ProfileItem,
} from '@/components/layout/main/header/styles'
import Text from "@/components/common/utils/Text";
import {ScaleLoader} from "react-spinners";
import {formatNumber} from "@/core/utils/common";
import {getMainTheme} from "@/core/utils/theme";
import {useMainContext} from "@/core/contexts/main";
import {useRouter} from "next/router";
import Link from "next/link";
import {useAuthContext} from "@/core/contexts/auth";
import { HOME } from '@/core/constants/urls';

const profileItems = [
	{ title: 'accountOverview', path: '/user/dashboard', icon: FaUser },
	{ title: 'transactions', path: '/user/transaction-history', icon: FaCoins },
	{ title: 'accountActivity', path: '/user/security', icon: FaHistory },
	{ title: 'twoFacAut', path: '/user/security', icon: FaUnlockAlt },
	{ title: 'referFriends', path: '/user/invite', icon: FaUsers },
]

export const ProfileDropdown = forwardRef((props, ref) => {

	const { theme } = useMainContext()
	const { locale } = useRouter()
	const { logout } = useAuthContext()
	const { profile, width } = props

	console.log(locale)

	return (
		<DropBody minWidth='250px' style={{ padding: '20px' }} ref={ref} $lang={locale} className={locale === 'en' ? 'right-3' : 'left-3'}>
			{profile && (
				<>
					<Flex>
						<Profile size={width < MOBILE_SIZE ? '34px' : '42px'}>
							{profile?.avatar ? (
								<Image src={profile?.avatar} alt=' ' size={width < MOBILE_SIZE ? '34px' : '42px'} />
							) : (
								<Avatar size={width < MOBILE_SIZE ? 18 : 22} />
							)}
						</Profile>
						<FlexColumn>
							{profile?.firstName && (
								<>
									<MText>{`${profile?.firstName} ${profile?.lastName}`}</MText>
									{/* <MText>{profile?.email}</MText> */}
								</>
							)}
						</FlexColumn>
					</Flex>
					<MText style={{ textAlign: 'start', margin: '10px 8px', fontSize: '0.75rem' }}>
						<Text tid='balance' />
					</MText>
					<Balance>{formatNumber(profile?.balance, { type: 'irt' })} تومان</Balance>
				</>
			)}
			{!profile && (
				<Flex style={{ justifyContent: 'center' }}>
					<ScaleLoader color={getMainTheme(theme, locale).active} size={15} />
				</Flex>
			)}
			{profileItems.map((item) => (
				<a href={HOME + item.path}>
					<ProfileItem>
						<item.icon style={{ marginBottom: '2px' }} size={18} color='#42958D' />
						<MText fontSize='0.75rem' style={{ margin: '0 10px' }}>
							<Text tid={item.title} />
						</MText>
					</ProfileItem>
				</a>
			))}
			<ProfileItem last={true} onClick={logout}>
				<FaSignOutAlt color='red' size={18} />
				<MText fontSize='0.75rem' style={{ margin: '0 10px' }}>
					<Text tid='exit' />
				</MText>
			</ProfileItem>
		</DropBody>
	)
})
