import Head from 'next/head'
import { useEffect } from 'react'


const Meta = ({ data = {} }) => {

    const { title, 
        description,
        // description = "کنزیکس، بهترین صرافی برای خرید و فروش ارزهای دیجیتال با قیمت های رقابتی و امنیت بینظیر. همین حالا شروع کنید و به جمع سرمایه گذاران موفق بپیوندید!",
         image, keywords, robots = 'index, follow' } = data

    const appName = process?.env.NEXT_PUBLIC_APP_NAME
    const appLogo = process?.env.NEXT_PUBLIC_APP_LOGO
    const appIcon = process?.env.NEXT_PUBLIC_APP_FAVICON

    const appTitle = title ? `${title} ― ${appName}` : appName


    useEffect(() => {  
        // Only run this code on the client side  
        window.dataLayer = window.dataLayer || [];  
        function gtag() {  
            window.dataLayer.push(arguments);  
        }  
        gtag('js', new Date());  
        gtag('config', 'G-T8Z1HQYH2Y');  

           // اضافه کردن کد Yektanet  
           const script = document.createElement('script');  
           script.async = true;  
           script.src = "https://cdn.yektanet.com/rg_woebegone/scripts_v3/ksODroDY/rg.complete.js";  
           script.onload = () => {  
               if (window.yektanet) {  
                   const a = new Date();  
                   const r = a.getFullYear().toString() + "0" + a.getMonth() + "0" + a.getDate() + "0" + a.getHours();  
                   window.yektanetAnalyticsObject = "yektanet";  
                   window.yektanet = window.yektanet || function () {  
                       window.yektanet.q.push(arguments);  
                   };  
                   window.yektanet.q = window.yektanet.q || [];  
               }  
           };  
   
           document.body.appendChild(script);  
   
           // پاکسازی: حذف اسکریپت هنگامUnmount  
           return () => {  
               document.body.removeChild(script);  
           }; 
    }, []);  


    return (
        <Head>
            {/* {appTitle} */}
            <title>صرافی کنزیکس، مرجع خرید و فروش ارزهای دیجیتال با بهترین قیمت و امنیت بالا</title>
            <meta charSet='utf-8' />
            <meta content='IE=edge' httpEquiv='X-UA-Compatible' />
            <link rel='manifest' href={'/manifest.json'} />

            {/*<meta content='upgrade-insecure-requests' httpEquiv='Content-Security-Policy' />*/}

            <meta
                content='width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no '
                name='viewport'
            />

            <link rel='icon' type='image/svg+xml' href={appLogo} />
            <link rel='icon' type='image/svg' href={appLogo} />
            <link rel='icon' type='image/x-icon' href={appIcon} />

            {keywords && <meta name='keywords' content={keywords} />}
            {description && (
                <>
                    <meta name='description' content={description} />

                    <meta name='og:description' content={description} />
                </>
            )}

            <meta name='og:site_name' content={appName} />
            <meta property='og:title' content={title || appTitle} />
            <meta property='og:image' content={image || appLogo} />
            <meta name='robots' content={robots} />
            <meta name="google-site-verification" content="Z7zPdfF24mQzDyYTo1B9uPFz6xdTj0ocYlG8k9vEcsk" />
            <script type="text/javascript" src={'/static/script.js'}></script>

         {/* yektanet */}
         

   
        </Head>
    )
}


export default Meta
