import axios from 'axios'
import { BASE_URL } from '../../constants/urls'

/**
 * Get api with filter queries
 * @param {Object} params
 * @param {string} path
 * @returns {Promise}
 */
const filterFetch = (params, path) => {
    return axios.get(BASE_URL + path, {
        params,
    })
}

/**
 * Get api with filter queries
 * @param {Object} params
 * @param {string} token
 * @param {string} path
 * @returns {Promise}
 */
const filterFetchWithToken = (params, token, path) => {
    return axios.get(BASE_URL + path, {
        params,
        headers: {
            'x-auth-token': token,
        },
    })
}

/**
 * Get api
 * @param {string} path
 * @returns {Promise}
 */
const normalFetch = (path) => {
    return axios.get(BASE_URL + path)
}

/**
 * Get api with token
 * @param {string} token
 * @param {string} path
 * @returns {Promise}
 */
const normalFetchWithToken = (token, path) => {
    return axios.get(BASE_URL + path, {
        headers: {
            'x-auth-token': token,
        },
    })
}

export { filterFetch, normalFetch, normalFetchWithToken, filterFetchWithToken }
