import MainContextProvider from '@/core/contexts/main';
import QueryContextProvider from '@/core/contexts/query';
import { SocketContextProvider } from '@/core/contexts/socket';
import CrispProvider from '../main/crisp/CrispProvider';

const Providers = (props) => {
  return (
    <MainContextProvider {...props}>
      <SocketContextProvider>
        <QueryContextProvider>
            <CrispProvider>{props.children}</CrispProvider>
        </QueryContextProvider>
      </SocketContextProvider>
    </MainContextProvider>
  );
};

export default Providers;
