import {translator} from "@/core/i18n";
import {useRouter} from "next/router";


const Text =  ({ tid, ...rest }) => {

    const { locale } = useRouter()

    return (
        <span {...rest}>
            {translator(locale, tid)}
        </span>
    )
}

export default Text
