import {createContext, useContext, useState} from "react";
import {MutationCache, QueryCache, QueryClient} from "react-query";


const context = createContext()

export const useQueryContext = () => {
    return useContext(context)
}


const QueryContextProvider = ({ children }) => {

    const [toast, setToast] = useState({
        show: false, message: "", isError: null
    })

    const [queryClient] = useState(() => new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                select: res => res.data,
                retry: 0
            }
        },
        queryCache: new QueryCache({
            onError: (res) => {
                if (res?.response?.status !== 401) {
                    setToast({
                        message: res?.response?.data?.message||'',
                        show: true,
                        isError: true
                    })
                }
            }
        }),
        mutationCache: new MutationCache({
            onError: (res) => {
                setToast({
                    message: res.response.data.message,
                    show: true,
                    isError: true
                })
            }
        })
    }))


    return (
        <context.Provider
            value={{
                toast,
                setToast,
                queryClient
            }}
        >
            {children}
        </context.Provider>
    )
}

export default QueryContextProvider
