import Image from 'next/image'
import Link from 'next/link'
import {
    Button,
    Logo,
    NavButtonList,
    NavLinkList,
    NavLinksWrapper,
    Navbar,
} from './styles/MainNav.styled'
import { useMainContext } from '@/core/contexts/main'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'
import {
    FaBuffer,
    FaChartLine,
    FaColumns,
    FaEquals,
    FaExchangeAlt,
    FaHistory,
    FaIdBadge,
    FaMoon,
    FaRegIdCard,
    FaRegUser,
    FaShieldAlt,
    FaSortAmountDownAlt,
    FaSortAmountUpAlt,
    FaSun,
    FaWallet,
    FaBloggerB,
} from 'react-icons/fa'
import Text from '@/components/common/utils/Text'
import MenuBar from './MenuBar'
import LogoImage from 'public/assets/images/logo/01.svg'
import { routes } from '@/core/constants/routes'
import { HOME } from '@/core/constants/urls'
import {
    CurrentOrdersIcon,
    AccountsIcon,
    OrdersHistoryIcon,
    OtcOrdersIcon,
    TransactionIcon,
    WalletsIcon,
} from '@/components/common/icons'
import { formatNumber } from '@/core/utils/common'
import TopkenzSelect from './TopkenzSelect'
import { useWalletRealTimeData } from '@/core/hooks/wallet/useWalletData'
import { useWalletMainHook } from '@/core/hooks/wallet/useWallet'
import { Flex } from '@/styles/styled'
import {
    Avatar,
    LinkRow,
    Menu,
    MLink,
    MOBILE_SIZE,
    Notification,
    Profile,
    UnreadNotificationIcon,
} from '../main/header/styles'
import { useUnreadNotificationQuery } from '@/core/services/react-query/notifications'
import { useRef, useState } from 'react'
import NotificationDropdown from '@/components/modal/NotificationDropdown'
import useClickOutside from '@/core/hooks/common/useClickOutside'
import { ProfileDropdown } from '../main/header/ProfileDropdown'
import { useProfileQuery } from '@/core/services/react-query/profile/useProfileQuery'
import { GiCardExchange, GiPayMoney } from 'react-icons/gi'
import { MdFace, MdNotificationsActive } from 'react-icons/md'
import { AnimatePresence } from 'framer-motion'
import { CgMenuLeftAlt } from 'react-icons/cg'
import { FcAbout, FcContacts } from 'react-icons/fc'
import { useAuthContext } from '@/core/contexts/auth'

const orderOptions = [
    {
        tid: 'current-orders',
        icon: <CurrentOrdersIcon width={32} />,
        link: HOME + '/user/current-orders',
    },
    {
        tid: 'orders-history',
        icon: <OrdersHistoryIcon width={32} />,
        link: HOME + '/user/orders-history',
    },
    {
        tid: 'otc-orders',
        icon: <OtcOrdersIcon width={32} />,
        link: HOME + '/user/otc-history',
    },
]

const getPropertyOptions = (balance) => [
    {
        element: (
            <div className={'flex flex-col gap-2 px-3 dark:text-gray-200 my-1'}>
                <div
                    className={
                        'w-full flex items-center justify-between text-sm'
                    }>
                    <span>
                        <Text tid={'balance'} />
                        <span>:</span>
                    </span>
                    <span dir={'ltr'} className={'mt-[4px]'}>
                        {formatNumber(balance.tooman, { type: 'irt' })} IRT
                    </span>
                </div>
                <p className='text-xs my-2 text-[#6B728080] dark:text-[#6B7280]'>
                    <Text tid={'balance-delay'} />
                </p>
                <div className={'flex items-center justify-between gap-2'}>
                    <a
                        href={HOME + '/user/wallets?openDepositModal=true'}
                        className={
                            'rounded-md w-[100px] h-[32px] bg-green-600 flex items-center justify-center cursor-pointer text-white'
                        }>
                        <Text tid={'deposit'} className={'text-sm'} />
                    </a>
                    <a
                        href={HOME + '/user/wallets?openWithdrawModal=true'}
                        className={
                            'rounded-md w-[100px] h-[32px] bg-red-500 flex items-center justify-center cursor-pointer text-white'
                        }>
                        <Text tid={'withdraw'} className={'text-sm'} />
                    </a>
                </div>
                <div className={'bg-gray-500 bg-opacity-20 w-full h-[1px]'} />
            </div>
        ),
    },
    {
        tid: 'mi-wallet',
        link: HOME + '/user/wallets',
        icon: <WalletsIcon width={32} />,
    },
    {
        tid: 'manage-wallets',
        link: HOME + '/user/accounts-cards?tab=wallet-accounts',
        icon: <WalletsIcon width={32} />,
    },
    {
        tid: 'cards-accounts',
        link: HOME + '/user/accounts-cards?tab=bank-accounts',
        icon: <AccountsIcon width={32} />,
    },
    {
        tid: 'transaction-history',
        link: HOME + '/user/transaction-history?tab=1',
        icon: <TransactionIcon width={32} />,
    },
    {
        tid: 'dc-transaction-history',
        link: HOME + '/user/transaction-history?tab=2',
        icon: <TransactionIcon width={32} />,
    },
]

export const NAV_LINKS = [
    { label: 'markets', route: routes.marketTrade },
    { label: 'otc-trade', route: routes.otcTrade3 },
    { label: 'p2p-trade', route: HOME + routes.p2pTrade, external: true },
    { label: 'invite-friends', route: HOME + routes.invite, external: true },
    { label: 'blog', route: routes.blog, external: true },
]

export default function MainNav() {
    const [profileOpen, setProfileOpen] = useState(false)
    const [notificationsOpen, setNotificationsOpen] = useState(false)

    const outRef = useRef()

    const {
        theme,
        toggleTheme,
        lang,
        toggleLang,
        token: isAuthed,
    } = useMainContext()

    const { data: profile } = useProfileQuery()
    const {
        data: notifications,
        isFetching: notificationsLoading,
        refetch: refetchUnreadNotifications,
    } = useUnreadNotificationQuery(1)

    const { logout } = useAuthContext()

    const onProfileItemsClicked = (type, path = null) => {
        if (type === 'exit') {
            logout()
        } else if (!path) {
            setNotificationsOpen(true)
        } else {
            window.location.href = HOME + `/user/${path}`
        }
    }

    const onOutsideClicked = () => {
        setProfileOpen(false)
        setNotificationsOpen(false)
    }
    useClickOutside(outRef, onOutsideClicked)

    const { width } = useWindowSize()

    const renderedNavLinks = NAV_LINKS.map((item, index) => {
        const { label, route } = item

        if (!!item.external)
            return (
                <li
                    className='hover:text-cyan-400 hover:scale-95 transition-colors shaky'
                    key={index}>
                    <a href={route}>
                        <Text tid={label} />
                    </a>
                </li>
            )

        return (
            <li
                className='hover:text-cyan-400 hover:scale-95 transition-colors shaky'
                key={index}>
                <Link href={route}>
                    <Text tid={label} />
                </Link>
            </li>
        )
    })

    const renderedMenuItems = () => {
        const menuItemsList = !!isAuthed ? getMenuLinks(lang) : getLinks(lang)

        const items = menuItemsList.flatMap((link) => {
            if (!isAuthed && link.needLogin) return []
            else if (!!link.external) {
                return [
                    <LinkRow key={link.path} justify='flex-start'>
                        {link.icon}
                        <MLink key={link.title}>
                            <a href={HOME + link.path} locale={'fa'}>
                                <Text tid={link.title} className='text-white' />
                            </a>
                        </MLink>
                    </LinkRow>,
                ]
            } else {
                return [
                    <LinkRow key={link.path} justify='flex-start'>
                        {link.icon}
                        <MLink key={link.title}>
                            <Link href={link.path} locale={lang}>
                                <Text tid={link.title} className='text-white' />
                            </Link>
                        </MLink>
                    </LinkRow>,
                ]
            }
        })

        return items
    }

    const { balanceData } = useWalletMainHook()

    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const menuRef = useRef()
    useClickOutside(menuRef, () => menuIsOpen && setMenuIsOpen(false))

    return (
        <Navbar>
            <NavLinksWrapper>
                <Logo header>
                    <Link href={'/'}>
                        <Image
                            src={LogoImage}
                            width={150}
                            height={'100%'}
                            alt='Limani EX'
                        />
                    </Link>
                </Logo>
                {width > 768 && <NavLinkList>{renderedNavLinks}</NavLinkList>}
            </NavLinksWrapper>

            <NavButtonList>
                {width > 480 ? (
                    <>
                        {!!isAuthed ? (
                            <>
                                <TopkenzSelect
                                    title={'orders'}
                                    options={orderOptions}
                                />
                                <TopkenzSelect
                                    title={'property'}
                                    options={getPropertyOptions(balanceData)}
                                />
                                <Flex
                                    style={{
                                        margin: '0 8px',
                                        position: 'relative',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setNotificationsOpen(true)}>
                                    <Notification
                                        size={20}
                                        className='hover:text-warn'
                                    />

                                    {notifications?.data?.length ? (
                                        <UnreadNotificationIcon className='bg-warn' />
                                    ) : null}
                                </Flex>
                                {notificationsOpen && (
                                    <NotificationDropdown
                                        ref={outRef}
                                        notifications={notifications}
                                        loading={notificationsLoading}
                                        onReadANotification={() =>
                                            refetchUnreadNotifications()
                                        }
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <Button link>
                                    <a href={HOME + routes.login}>
                                        <Text tid='login' />
                                    </a>
                                </Button>
                                <Button paddingX='2rem' link>
                                    <a href={HOME + routes.register}>
                                        <Text tid='register' />
                                    </a>
                                </Button>
                            </>
                        )}
                    </>
                ) : !isAuthed ? (
                    <Button link>
                        <a href={HOME + routes.login}>
                            <Text tid='login' />
                        </a>
                    </Button>
                ) : null}
                <Button bg onClick={toggleTheme}>
                    {theme === 'light' ? <FaMoon /> : <FaSun />}
                </Button>
                {width > 768 && (
                    <Button bg='secondary' onClick={toggleLang}>
                        <Text tid='lang' />
                    </Button>
                )}
            </NavButtonList>

            {isAuthed && (
                <Profile
                    onClick={() => setProfileOpen((state) => !state)}
                    size={width < 768 ? '34px' : '42px'}>
                    {profile?.avatar ? (
                        <Image
                            src={profile?.avatar}
                            alt=' '
                            size={width < 768 ? '34px' : '42px'}
                        />
                    ) : (
                        <Avatar size={width < 768 ? 18 : 22} />
                    )}
                    {profileOpen && (
                        <ProfileDropdown
                            ref={outRef}
                            width={width}
                            profile={profile}
                            onProfileItemsClicked={onProfileItemsClicked}
                        />
                    )}
                </Profile>
            )}

            {/* {width <= 768 && (
                <MenuBar
                    navLinks={renderedNavLinks}
                    theme={theme}
                    toggleTheme={toggleTheme}
                    toggleLang={toggleLang}
                />
            )} */}

            {width < 768 && (
                <CgMenuLeftAlt
                    onClick={() => setMenuIsOpen((state) => !state)}
                    size={28}
                    color='#c3c5b7'
                />
            )}

            <AnimatePresence mode='wait'>
                {menuIsOpen && (
                    <Menu
                        variants={menuVariants}
                        animate='in'
                        exit='out'
                        initial='out'
                        ref={menuRef}>
                        {renderedMenuItems()}
                    </Menu>
                )}
            </AnimatePresence>
        </Navbar>
    )
}

const getLinks = (lang) => [
    {
        title: 'markets',
        path: routes.marketTrade,
        icon: <FaChartLine size={18} />,
    },
    {
        title: 'otc-trade',
        path: routes.otcTrade3,
        icon: <FaExchangeAlt size={18} />,
    },
    {
        title: 'p2p-trade',
        path: routes.p2pTrade,
        icon: <GiCardExchange size={18} />,
        external: true,
    },
    {
        title: 'invite-friends',
        path: routes.invite,
        icon: <MdFace />,
        external: true,
    },
    {
        title: 'blog',
        path: routes.blog,
        icon: <FaBloggerB />,
        external: true,
    },
]

const getMenuLinks = (lang) => [
    { title: 'main', path: '/', icon: <FaEquals /> },
    {
        title: 'dashboard',
        path: '/user/dashboard',
        icon: <FaColumns />,
        external: true,
        needLogin: true,
    },
    {
        title: 'security',
        path: '/user/security',
        icon: <FaShieldAlt />,
        external: true,
        needLogin: true,
    },
    {
        title: 'authentication',
        path: '/user/authentication',
        icon: <FaIdBadge />,
        external: true,
        needLogin: true,
    },
    {
        title: 'my-wallet',
        path: '/user/wallets',
        icon: <FaWallet />,
        external: true,
        needLogin: true,
    },
    {
        title: 'transaction-history',
        path: '/user/transaction-history',
        icon: <FaSortAmountDownAlt />,
        external: true,
        needLogin: true,
    },
    {
        title: 'current-orders',
        path: '/user/current-orders',
        icon: <FaSortAmountUpAlt />,
        external: true,
        needLogin: true,
    },
    {
        title: 'orders-history',
        path: '/user/orders-history',
        icon: <FaHistory />,
        external: true,
        needLogin: true,
    },
    {
        title: 'otc-orders',
        path: '/user/otc-history',
        icon: <FaBuffer />,
        external: true,
        needLogin: true,
    },
    {
        title: 'cards-accounts',
        path: '/user/accounts-cards',
        icon: <FaRegIdCard />,
        external: true,
        needLogin: true,
    },
    {
        title: 'my-notifications',
        path: '/user/notifications',
        icon: <MdNotificationsActive />,
        external: true,
        needLogin: true,
    },
    {
        title: 'invite-friends',
        path: '/user/invite',
        icon: <MdFace />,
        external: true,
        needLogin: true,
    },
    {
        title: 'blog',
        path: 'https://blog.kenzx.io/',
        icon: <FaBloggerB />,
        external: true,
    },
]
//   https://parvazerangha.com/topkenz/
const menuVariants = {
    in: {
        x: 0,
        transition: { duration: 0.4 },
    },
    out: {
        x: '-300px',
        transition: { duration: 0.4 },
    },
}
