import { useQuery } from 'react-query'
import { useMainContext } from '@/core/contexts/main'
import { normalFetchWithToken } from '../../fetch-api/get'
import { HOME } from '@/core/constants/urls'

/**
 * Profile - Get api
 * @returns {UseQuery}
 */
const useProfileQuery = () => {
	const { token, setToken } = useMainContext()

	return useQuery('fetch-profile', () => normalFetchWithToken(token, 'profile'), {
		enabled: !!token,
		select: (res) => res.data.data,
		refetchOnWindowFocus: false,
		staleTime: 30 * 60 * 1000,
		retry: 0,
		onError: (res) => {
			if (res.response.status === 401) {
				setToken('')
				window.location.href = HOME + '/user/register-signin'
			}
		},
	})
}

export { useProfileQuery }
