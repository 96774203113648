import { useMutation, useQuery } from 'react-query'
import { normalFetch } from '@/core/services/fetch-api/get'
import { useMainContext } from '@/core/contexts/main'
import { deleteApi } from '@/core/services/fetch-api/delete'

export const AUTH_KEYS = {
    PROFILE: 'profile',
    LOGOUT: 'logout',
}

export const useGetProfile = () => {
    const { token, setToken } = useMainContext()

    return useQuery(AUTH_KEYS.PROFILE, () => normalFetch('profile'), {
        cacheTime: 5 * 60 * 1000,
        staleTime: 5 * 60 * 1000,
        enabled: !!token,
        onError: () => {
            setToken('')
        },
    })
}

export const useLogout = (onSuccess) => {
    return useMutation(AUTH_KEYS.LOGOUT, () => deleteApi('auth/token'), {
        onSuccess,
    })
}
