import Link from 'next/link'
import { Menu } from '@headlessui/react'

import { FaBars, FaMoon, FaSun } from 'react-icons/fa'

import { NAV_LINKS } from './MainNav'
import Text from '@/components/common/utils/Text'
import { Button } from './styles/MainNav.styled'
import styled from 'styled-components'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'
import { routes } from '@/core/constants/routes'
import { useMainContext } from '@/core/contexts/main'
import { HOME } from '@/core/constants/urls'

export default function MenuBar({ theme, toggleTheme, toggleLang }) {
    const { token: isAuth } = useMainContext()

    const { width } = useWindowSize()

    const renderedItems = NAV_LINKS.map((item) => {
        const { label, route, external } = item

        if (!!external)
            return (
                <Menu.Item>
                    <a href={route} className='block p-1 text-center'>
                        <Text tid={label} />
                    </a>
                 
                </Menu.Item>
            )

        return (
            <Menu.Item>
                <Link href={route} className='block p-1 text-center'>
                    <Text tid={label} />
                </Link>
            </Menu.Item>
        )
    })

    const renderedRegisterButtons =
        width < 480 ? (
            <div className='flex flex-col gap-y-2'>
                {!!isAuth ? (
                    <Button bg='primary' link>
                        <a href={HOME + routes.dashboard}>
                            <Text tid={'user-dashboard'} />
                        </a>
                    </Button>
                ) : (
                    <>
                        <Button bg='primary' link>
                            <a href={HOME + routes.login}>
                                <Text tid='login' />
                            </a>
                        </Button>
                        <Button bg='primary' link>
                            <a href={HOME + routes.register}>
                                <Text tid='register' />
                            </a>
                        </Button>
                    </>
                )}
            </div>
        ) : null

    const renderedButtons = (
        <div className='flex gap-x-2 border-y rounded-t-md rounded-b-md py-4 mt-4'>
            <Menu.Item>
                <Button bg='primary' onClick={toggleTheme} width='4rem'>
                    {theme === 'light' ? <FaMoon /> : <FaSun />}
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button bg='primary' onClick={toggleLang} width='4rem'>
                    <Text tid='lang' />
                </Button>
            </Menu.Item>
        </div>
    )

    return (
        <Menu>
            <Menu.Button className={'ms-5 p-1'}>
                <FaBars size={20} />
            </Menu.Button>
            <MenuItemsWrapper>
                {renderedItems}
                {renderedRegisterButtons}
                {renderedButtons}
            </MenuItemsWrapper>
        </Menu>
    )
}

const MenuItemsWrapper = styled(Menu.Items)`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    position: absolute;
    top: 100%;
    inset-inline-end: 1rem;

    padding: 2rem 4rem;
    border-radius: 8px;
    background-color: ${(props) => props.theme.primaryBg};
    border: 1px solid ${(props) => props.theme.border};
`
