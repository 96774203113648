export const faMessages = {
    APP_TITLE: process.env.NEXT_PUBLIC_APP_NAME,
    'lorem-ipsum':
        'طرح‌نما یا لورم ایپسوم (به انگلیسی: Lorem ipsum) به نوشتاری آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این نوشتار به‌عنوان عنصری از ترکیب‌بندی برای پُر کردن صفحه و ارائهٔ اولیهٔ شکل ظاهری و کلیِ طرح سفارش‌گرفته‌شده‌استفاده می‌کند، تا ازنظر گرافیکی نشانگر چگونگی نوع و اندازهٔ قلم و ظاهرِ متن باشد.طرح‌نما یا لورم ایپسوم (به انگلیسی: Lorem ipsum) به نوشتاری آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این نوشتار به‌عنوان عنصری از ترکیب‌بندی برای پُر کردن صفحه و ارائهٔ اولیهٔ شکل ظاهری و کلیِ طرح سفارش‌گرفته‌شده‌استفاده می‌کند، تا ازنظر گرافیکی نشانگر چگونگی نوع و اندازهٔ قلم و ظاهرِ متن باشد.',
    undefined: ' ',
    rial: 'ریال',
    login: 'ورود',
    register: 'ثبت‌نام',
    lang: 'En',
    main: 'صفحه اصلی',
    security: 'امنیت',
    authentication: 'احراز هویت',
    profile: 'پروفایل',
    'my-wallet': 'کیف پول من',
    terms: 'قوانین کنز ایکس',
    dictionary: 'لغتنامه ارز دیجیتال',
    'dictionary-page-title': 'لغتنامه',

    'navbar-market': 'بازار',
    'navbar-news': 'اخبار',
    'navbar-blog': 'وبلاگ',
    'navbar-guide': 'راهنما',
    'navbar-about': 'درباره ما',
    'hero-title': 'به دنیای جذاب ارز دیجیتال با کنز ایکس خوش آمدید!',
    'hero-paragraph':
        'از بیت‌کوین تا اتریوم و سایر ارزهای دیجیتال، در اینجا بهترین منابع و اطلاعات را برای شما فراهم کرده‌ایم.',
    'register-now': 'ثبت‌نام سریع',
    'trade-now': 'معامله سریع',
    buy: 'خرید',
    sell: 'فروش',
    'feature__quantity--1': '+1200',
    'feature__quantity--2': '+5',
    'feature__quantity--3': '+76',
    'feature__title--1': 'نفر',
    'feature__title--2': 'میلیون معامله',
    'feature__title--3': 'ارز',
    'feature__body--1': 'کاربر فعال',
    'feature__body--2': 'بیشترین تعداد معاملات',
    'feature__body--3': 'تعداد ارزهای دیجیتال لیست شده',
    'about-us__title--1': 'با کنز ایکس بیشتر آشنا شوید',
    'about-us__title--2': 'ما کی هستیم؟',
    'about-us__body':
        'تیم صرافی کنز ایکس، یک گروه از متخصصین مبتنی بر ارزهای دیجیتال، با تجربه‌های متنوع در زمینه‌های فناوری، امنیت، و تجارت الکترونیک می باشند که از سال 1399 مشغول به فعالیت تیمی می باشند. گروه صرافی کنز ایکس از اواخر سال 1401 تا کنون، با هدف فراهم کردن یک پلتفرم مطمئن و کارآمد برای معاملات و سرمایه‌گذاری در ارزهای دیجیتال فعالیت خود را ادامه داده و هر روز در تلاش برای به روز شدن و نوآوری در این زمینه می باشند ماموریت ما: هدف ما در صرافی کنز ایکس، ایجاد یک فضای امن و دسترسی آسان برای کاربران جدید و حرفه‌ای در دنیای ارزهای دیجیتال است. ما برای تشکیل یک جامعه سازنده و پویا از سرمایه‌گذاران و تازه‌واردان علاقه‌مند به این حوزه، دعوت می‌کنیم',
    'about-us__btn': 'بیشتر بدانید',
    'why-us__title': 'چرا کنز ایکس ؟ ',
    'why-us__body':
        'ماموریت ما: هدف ما در صرافی کنز ایکس، ایجاد یک فضای امن و دسترسی آسان برای کاربران جدید و حرفه‌ای در دنیای ارزهای دیجیتال است. ما برای تشکیل یک جامعه سازنده و پویا از سرمایه‌گذاران و تازه‌واردان علاقه‌مند به این حوزه، دعوت می‌کنیم',

    'why-us__box--1__header': 'فرایند ثبت‌نام آسان و سریع',
    'why-us__box--2__header': 'پایداری و امنیت',
    'why-us__box--3__header': 'معاملات با کارمزد کم',
    'why-us__box--4__header': 'پشتیبانی سریع و قوی ',
    'why-us__box--1__body':
        'شما در کنز ایکس به سادگی و تنها در چند ثانیه با ثبت ایمیل خود میتوانید ثبت نام کنید. ',
    'why-us__box--2__body':
        'امنیت و پایداری پلتفرم ما از اهمیت بالایی برخوردار است تا سرمایه‌های کاربران ما همواره تحت حفاظت باشند.',
    'why-us__box--3__body':
        'صرافی کنز ایکس به شما این امکان را می‌دهد تا شما با کمترین کارمزد ممکن، به سرعت و با قیمت مناسب معامله کنید.',
    'why-us__box--4__body':
        'تیم پشتیبانی صرافی کنز ایکس 7 روز هفته به صورت 24 ساعته آماده است تا به هر سوالی پاسخ دهد.',
    'markets-head': 'قیمت لحظه ای ارزهای دیجیتال',
    'to-tooman': 'تومان',
    'to-tether': 'تتر',
    coins: 'نام',
    trade: 'خرید و فروش',
    medium: 'متوسط',
    low: 'کم',
    'daily-change': 'تغییر 24 ساعته',
    'volume-price': 'حجم معاملات 24h',
    'see-more': 'مشاهده بیشتر',
    'see-less': 'بستن',
    'apps__title--1': 'کنز ایکس در موبایل شما!',
    'apps__title--2': 'اپلیکیشن خرید و فروش ارز دیجیتال کنز ایکس',
    apps__body:
        'خرید و فروش ارز های رمزنگاری شده این روزها به شغل دوم و حتی درآمد اصلی بسیاری از افراد در سراسر دنیا تبدیل شده است. آنها اعتقاد دارند که رمزارزها بسیاری را میلیونر خواهند کرد. برای آنکه شما هم بتوانید در بازار ارزهای دیجیتال معامله و سرمایه گذاری کنید، به عضویت در یک صرافی احتیاج دارید تا معاملات خود را به صورت ایمن انجام دهید. با استفاده از اپلیکیشن خرید و فروش ارز دیجیتال کنز ایکس، هرکجا و در هر زمان به بازار رمزارزها دسترسی داشته باشید.',
    apps__download__title: 'برای دانلود اسکن کن!',
    apps__download__body: 'iOS & Android',
    myket: 'مایکت',
    bazar: 'بازار',
    'google-play': 'گوگل پلی',

    'faq__title--1': 'برخی سوالات پرتکرار شما',
    'faq__title--2': 'سوالات متداول',
    'faq__tab--1__header': 'کنز ایکس چیست؟',
    'faq__tab--2__header': 'قیمت رمزارزها را از کجا ببینم؟',
    'faq__tab--3__header':
        'چطور شروع کنم و برای شروع معامله چه چیزهایی لازم است؟',
    'faq__tab--4__header':
        'چگونه بیت‌کوین، تتر و سایر رمزارزها را در کنز ایکس  بخرم؟',
    'faq__tab--1__body':
        'کنز ایکس اولین و بزرگ‌ترین بازار ایرانی ارزهای دیجیتال و بستری امن و ساده برای خرید و فروش مستقیم و سریع رمزارزها و سرمایه‌گذاری در بازار آن‌ها است. در صرافی معتبر کنز ایکس ، بی‌واسطه معامله کنید.',
    'faq__tab--2__body': '',
    'faq__tab--3__body': '',
    'faq__tab--4__body': '',

    'conflict-page-title': 'مدیریت تعارض منافع در صرافی ارز دیجیتال کنز ایکس',
    'conflict-page-item-1': `در کنز ایکس ما به شفافیت و اخلاق در عملکردمان اولویت اول را می‌دهیم. تعارض منافع یکی از مسائل مهم در صنعت ارز دیجیتال است که بر معاملات و اعتماد مشتریان تأثیر می‌گذارد. به همین دلیل، ما یک چارچوب کامل برای مدیریت تعارض منافع ایجاد کرده‌ایم تا اطمینان حاصل کنیم که فعالیت‌های ما همواره منعکس کننده اصول انصاف و شفافیت می باشند.

	اصول صرافی کنز ایکس برای مدیریت تعارض منافع:
	1-	شفافیت و اطلاع‌رسانی: ما از اهمیت بالایی به شفافیت در مورد تعارض منافع می‌پردازیم. مشتریان ما می‌توانند همیشه اطلاعات دقیق در مورد روابط و تعاملات ما با دیگر اشخاص را در سایت کنز ایکس بیابند.
	2-	ممنوعیت معاملات داخلی: هیچ کارمند یا عاملی که در مدیریت صرافی دیجیتال ما شرکت دارد نباید در معاملات داخلی مشارکت کند.
	3-	حفاظت از اطلاعات مشتریان: اطلاعات حساس مشتریان برای ما اولویت دارد و هیچ‌گونه انتقال یا مشارکت در انتقال اطلاعات به اشخاص ثالث مجاز نیست.
	4-	گزارش‌دهی تعارض منافع: تمام اعضای تیم ما ملزم به گزارش هر گونه تعارض منافع به مسئولین مربوطه هستند. ما تضمین می‌کنیم که همه گزارش‌ها با کمترین تأخیر پیگیری و بررسی می‌شوند.
	
	نظارت و تصحیح در کنز ایکس:
	1-	تحقیقات مستقل: تعارض منافع به صورت دوره‌ای توسط تحقیقات مستقل ارزیابی می‌شود و هر تغییر در مقررات و الگوهای نظارتی به منظور بهینه‌سازی آنها اعمال می‌شود.
	2-	  تنبیهات: تخلفات از مقررات مربوط به تعارض منافع با تنبیهات مناسبی مواجه می‌شوند تا اصول انصاف حفظ شود.
	 ما در کنز ایکس عهده‌دار ایجاد یک محیط اعتمادسازی برای مشتریان و تضمین اینکه تعارض منافع هیچ‌گاه به زیان آنها نخواهد بود، هستیم. از شما دعوت می‌کنیم تا با ما همراه باشید و از خدمات بهترین صرافی ارز دیجیتال کنز ایکس بهره‌مند شوید.
	کنز ایکس برای آینده دیجیتال بهتر
	`,

    guide__title: 'چگونه ارزهای دیجیتال بخریم؟',
    'guide__box--1__header': 'ثبت نام ',
    'guide__box--2__header': 'احراز هویت',
    'guide__box--3__header': 'واریز ریالی آنلاین',
    'guide__box--4__header': 'معامله ارزدیجیتال',
    'guide__box--1__body':
        'ثبت نام خود را در کنز ایکس تکمیل کنید و ایمیل خود را تایید کنید. ',
    'guide__box--2__body':
        'فرایند احراز هویت خود را به شکل کامل طبق موارد خواسته شده در وبسایت انجام دهید. ',
    'guide__box--3__body':
        'پس از انجام فرایند احراز هویت شما قادر خواهید بود وجه نقد ریالی را واریز کنید. ',
    'guide__box--4__body':
        'از انجام فرایند واریز وجه شما قادر خواهید بود در بازار ارزدیجیتال معاملات خود را انجام دهید.',

    guide: 'آموزش خرید و فروش',
    FGuide: 'آموزش خرید و فروش',
    'guide-title-0': 'مراحل خرید و فروش:',
    'guide-desc-0':
        'در این بخش، مراحل مختلف خرید و فروش ارزهای دیجیتال از ثبت نام تا انجام معامله به طور دقیق توضیح داده شده است. همچنین، نکات مهمی در مورد امنیت و راهنمایی های مفید نیز ارائه شده است.',
    'guide-title-1': 'نکات امنیتی:',
    'guide-desc-1':
        'در این بخش، به مسائل امنیتی مرتبط با معاملات ارزهای دیجیتال پرداخته میشه. از جمله این مسائل می توان به استفاده از ویژگی های امنیتی سایت و پیشگیری از کلاهبرداری ها اشاره کرد فناوری‌های امنیتی که در پلتفرم اکسچنج ارز دیجیتال کنز ایکس مورد استفاده قرار می‌گیرند، شامل تکنولوژی‌ها و روش‌های پیچیده‌تری هستند که به دقت و اثربخشی بیشتری برای حفاظت از اطلاعات و امنیت معاملات ارزی ارائه می‌دهند. در ادامه، به برخی از این فناوری‌ها اشاره می‌کنم:.',
    'guide-title-2': 'رمزنگاری (Encryption):',
    'guide-desc-2':
        'از رمزنگاری برای محافظت از داده‌های ارسالی از طریق شبکه استفاده می‌شود. با استفاده از پروتکل HTTPS که بر اساس SSL/TLS است، اطلاعات بین کاربر و وب‌سایت با استفاده از الگوریتم‌های رمزنگاری قوی (مانند RSA یا AES) رمزنگاری می‌شوند. این کار باعث می‌شود که اطلاعات ارسالی توسط هر کسی که به شبکه دسترسی داشته باشد، نامفهوم باشد.',
    'guide-title-3': 'تأیید هویت دو عاملی (Two-Factor Authentication - 2FA):',
    'guide-desc-3':
        'برای تأمین امنیت ورود کاربران، از تأیید هویت دو عاملی استفاده می‌شود. این روش نه تنها نیاز به نام کاربری و رمز عبور دارد، بلکه به یک عامل دیگر (مانند کد ارسال شده به تلفن همراه) نیز نیاز دارد که توسط کاربر ارائه شود. این روش احتمال ورود غیرمجاز به حساب کاربری را به شدت کاهش می‌دهد.',
    'guide-title-4':
        'مانیتورینگ و تحلیل فعالیت‌ها (Monitoring and Activity Analysis):',
    'guide-desc-4':
        'وب‌سایت‌ اکسچنج ارز دیجیتال کنز ایکس باید فعالیت‌های کاربران را به طور مداوم نظارت کند. این شامل شناسایی الگوهای غیرمعمول مانند تراکنش‌های بزرگ، ورود از مکان‌های ناشناس، یا تغییرات نامترقبه در فعالیت حساب‌ها می‌شود.',
    'guide-title-5': 'Firewall و محافظت در برابر حملات DDoS:',
    'guide-desc-5':
        'استفاده از فایروال‌های پیشرفته و سیستم‌های محافظتی برای مقابله با حملات DDoS از اهمیت بالایی برخوردار است. این سیستم‌ها ترافیک ورودی را مورد ارزیابی قرار داده و حملات DDoS را شناسایی و کنترل منحصر به فرد را به سایت اعمال می‌کنند.',
    'guide-title-6': 'مدیریت ریسک و امنیت سایبری:',
    'guide-desc-6': `وبسایت پلتفرم اکسچنج ارز دیجیتال کنز ایکس باید دارای استراتژی‌های مدیریت ریسک و امنیت سایبری مناسب باشد. این شامل ارزیابی و مدیریت مخاطرات امنیتی، آموزش کاربران درباره رفتارهای امنیتی، و توسعه نرم‌افزارهای مقاوم در برابر نفوذ می‌شود.
	این فناوری‌ها و روش‌ها به صورت هماهنگ استفاده می‌شوند تا به افزایش امنیت و حفاظت از کاربران و معاملات ارزی در وب سایت کنز ایکس کمک کند.
	`,

    'get-started__title--1': 'بخرید، معامله کنید ، هولد کنید',
    'get-started__title--2__part1': 'بیش از 100 ارزدیجیتال در ',
    'get-started__title--2__part2': 'کنز ایکس',
    'get-started__title--2__part3': ' منتظر شماست!',
    'get-started__btn': 'ماجراجویی خود را شروع کنید!',

    footer_about: 'درباره کنز ایکس',
    FIrtToTether: 'تبدیل ریال به تتر',
    FContact: 'ارتباط با ما',
    FAbout: 'درباره ما',
    FSupport: 'پشتیبانی',
    FConflict: 'مدیریت تعارض منافع',
    FTransform: 'تحولی عظیم',
    FTerms: 'قوانین کنز ایکس',
    FServices: 'ویژگی‌ها و خدمات',
    FResponsibilities: 'مسئولیت و تعهدات کنز ایکس',
    FSocial: 'شبکه‌های اجتماعی',
    'footer_useful-links': 'لینک های مفید',
    'footer_quick-access': 'دسترسی سریع',
    FDictionary: 'لغتنامه ارز دیجیتال',
    FFees: 'کارمزدها',
    FTrade: 'معامله حرفه‌ای',
    FInvite: 'دعوت از دوستان',
    FPrivacy: 'حفظ حریم خصوصی',
    FAuth: 'احراز هویت',
    FSecurity: 'امنیت',
    FSystem: 'سیستم امنیتی نفوذ ناپذیر',
    FFaq: 'سوالات متداول',
    footer_blogs: 'آخرین بلاگ ها',
    footer_events: 'رویدادها',
    FNft: 'آشنایی با nft',
    FStableCoin: 'استیبل‌کوین چیست؟',
    footer_contact: 'تماس با ما',
    'footer-contact-us-info': `کرج، کیانمهر، کوچه اطهر، بن بست احداثی، طبقه دوم، واحد ۴`,
    footer_newsletter: 'عضویت در خبرنامه کنز ایکس',
    footer_btn: 'عضویت',
    copyRight: 'تمامی حقوق مادی و معنوی این وبسایت متعلق به کنز ایکس می باشد.',

    // --------------------------------------------------------------------------------
    'trade-section-title': 'کنز ایکس',
    'trade-section-subtitle': 'همراه معاملات شما',
    'trade-section-description':
        'پلتفرم معاملاتی و کیف‌پول امن ارزها و دارایی‌های دیجیتال',
    // 'register-now': 'همین حالا ثبت‌نام کنید',
    coin: 'ارز',
    'you-pay': 'پرداخت می‌کنید',
    'you-receive': 'دریافت می‌کنید',
    'do-exchange': 'تبدیل کن',
    'last-price': 'آخرین قیمت',
    'daily-chart': 'چارت روزانه',
    'why-us.title-1': 'امنیت در معاملات',
    'why-us.description-1':
        'کنز ایکس مدعی سرعت در نامنویسی و احراز هویت نیست، اما فضای معامله در بازار جهانی رمزارزها را برای شما و تا حد نیازتان امن و قابل اعتماد نموده است. کنز ایکس این اطمینان را به شما میدهد که دارائی رمزارزی شما را در فضایی امن و دردسترس نگهداری میکند تا هیچ فردی بجز شما به آن دسترسی نداشته باشد.',
    'why-us.title-2': 'حجم معامله بالا',
    'why-us.description-2':
        'کنز ایکس مدعی دارا بودن بزرگترین بازار معاملاتی رمزارز ایران نیست، اما گستردگی معاملاتش در بازار رمزارزها، شرایط لازم جهت انجام بهترین خرید و فروشها برای شما را تضمین میکند.',
    'why-us.title-3': 'رمزارزهای قابل اطمینان',
    'why-us.description-3':
        'کنز ایکس مدعی ارائه کننده بیشترین تعداد رمزارز قابل معامله نیست، اما تیمی متخصص در حال رصد رفتار رمزارزهای موجود و جدیدالورد در بازار هستند تا از آسیب احتمالی شما در معامله رمزارزهای فیک، بی پایه و بدون پشتوانه جلوگیری کنند. ',
    'why-us.title-4': 'سپرهای امنیتی مدرن',
    'why-us.description-4':
        'تیم امنیتی پیشرفته کنز ایکس با ابزارهای مدرن و به‌روز، همواره برای حفظ امنیت دارایی کاربران تلاش می‌کنند. ذخیره امن دارایی کاربران در کیف پول‌های سرد و تایید هویت دو عاملی از جمله این ابزارها هستند.',
    'why-us.title-5': ' گزارشدهی حرفه‌ای',
    'why-us.description-5':
        'کنز ایکس مدعی ارائه متنوعترین گزارشات رمزارزی نیست، اما طیف گزارشات مورد نیاز کاربران را به سهولت و در کمترین زمان ممکن در اختیار ایشان قرار میدهد.',
    'why-us.title-6': 'پشتیبانی کامل',
    'why-us.description-6':
        'کنز ایکس مدعی ارائه سریعترین پشتیبانی نیست، اما تیم پشتیبانی متخصصی برای کاربران تشکیل داده که به طور دلسوزانه و جانبدارانه از کاربران حمایت نموده و 24*7 پیگیر رفع مشکلات کاربران هستند.',
    'why-us.title-7': 'کارمزد منصفانه',
    'why-us.description-7':
        'کنز ایکس مدعی دریافت کمترین کارمزد بازار رمزارز ایران نیست، اما ضمن دریافت کارمزد منصفانه، متنوعترین خدمات را با بروزترین متدها در اختیار کاربران قرار میدهد.',
    'intro-title-1': 'سرمایه‌گذاری روی دارایی‌های دیجیتال، آینده‌نگری است',
    'intro-body-1-title-1':
        ' کنز ایکس دریچه‌ای مطمعن و علمی برای ورود به بازارهای جهانی',
    'intro-body-1-description-1': `
	حفظ ارزش دارایی در برابر تورم و بحران‌های اقتصادی
	خرید و فروش دارایی با ارزش ذاتی
	حمایت و سرمایه‌گذاری متخصصین و کارآفرینان ایرانی
	`,
    'intro-title-2': 'چطور دارایی دیجیتال داشته باشیم؟',
    'intro-body-2-title-1': 'ثبت نام در کنز ایکس در کمتر از ۵ دقیقه',
    'intro-body-2-description-1':
        'فرآیند ثبت نام و احراز هویت اولیه در کنز ایکس بسیار سریع است. برای دسترسی به کیف پول و بازارها کافی‌ است ثبت نام اولیه را انجام دهید.',
    'intro-body-2-title-2': 'تکمیل مراحل احراز هویت (KYC)',
    'intro-body-2-description-2':
        'انجام مراحل احراز هویت، دسترسی کامل به تمام امکانات و شرکت در عرضه‌های اولیه صرافی را برای شما فراهم می‌کند.',
    'intro-body-2-title-3': 'ورود به بازار و ثبت سفارش خرید و فروش',
    'intro-body-2-description-3':
        'برای خرید و فروش ارزهای دیجیتال می‌توانید به بازار اختصاصی هر ارز وارد شوید و سفارش خود را ثبت کنید.',
    'intro-body-2-title-4': 'مدیریت سرمایه با استفاده از امکانات کنز ایکس',
    'intro-body-2-description-4':
        'شما با پلتفرم کنز ایکس می‌توانید از کاملترین و حرفه‌ای‌ترین امکانات معامله‌گری جهت مدیریت دارایی خود استفاده کنید',
    TOMAN: 'تومان',
    'guide-of-use': 'راهنمای استفاده',
    'home-page': 'صفحه اصلی',
    'how-to-register': 'ثبت‌نام و احراز هویت',
    'how-to-trade': 'معاملات و سفارشات',
    'about-us': 'درباره ما',
    'terms-of-use': 'شرایط استفاده',
    'customer-services': 'خدمات مشتریان',
    'deposit-and-withdraw': 'واریز و برداشت',
    FAQ: 'سوالات متداول',
    authorization: 'احراز هویت',
    'invite-friends': 'دعوت از دوستان',
    'our-services': 'خدمات ما',
    'user-dashboard': 'پنل کاربری',
    'my-wallets': 'کیف پول‌های من',
    'otc-trade': 'معامله سریع',
    'p2p-trade': 'معامله حرفه‌ای',
    training: 'آموزش',
    blog: 'وبلاگ',
    careers: 'فرصت‌های شغلی',
    news: 'اخبار',
    markets: 'بازارها',
    toman: 'تومان',
    // buy: 'خرید',
    // sell: 'فروش',
    'please-authenticate': 'لطفاً وارد حساب‌کاربری خود شوید',
    'sell-invoice': 'فاکتور فروش',
    'buy-invoice': 'فاکتور خرید',
    unit: 'واحد',
    support: 'پشتیبانی',
    fee: 'کارمزد',
    off: 'تخفیف',
    'off-over-fee': 'تخفیف بر کارمزد',
    'affiliate-profit': 'سود معرف',
    'amount-to-pay': 'مبلغ قابل پرداخت',
    'amount-to-receive': 'مقدار دریافتی',
    'cancel-order': 'لغو سفارش',
    'go-on': 'ادامه',
    'internal-wallet': 'کیف‌پول داخلی',
    'external-wallet': 'کیف‌پول خارجی',
    'select-network': 'انتخاب شبکه',
    'internal-account': 'حساب داخلی',
    'external-account': 'حساب خارجی',
    'select-wallet': 'انتخاب کیف‌پول',
    'select-bank-account': 'انتخاب شبای بانکی',
    'no-banks-found': 'شبای بانکی یافت نشد',
    'banks-list': 'لیست حساب‌های بانکی',
    'no-wallets-found': 'کیف‌پولی یافت نشد',
    'wallets-list': 'لیست کیف‌پول‌ها',
    'invoice-popup-hint':
        'لازم به ذکر است که قیمت نهایی برای محاسبه سفارش خرید شما تقریبی می باشد.',
    'increase-balance': 'افزایش موجودی',
    'not-enough-balance': 'موجودی کافی نیست',
    'withdraw-fee': 'کارمزد شبکه',
    'transfer-fee': 'کارمزد انتقال',
    'minimum-amount-not-reached': 'مبلغ کمتر از حد مجاز',
    'reached-maximum-amount': 'مبلغ بیشتر از حد مجاز',
    'previous-step': 'مرحله قبل',
    'payment-succeed': 'پرداخت با موفقیت انجام شد',
    'buy-payment-succeed': 'خرید با موفقیت انجام شد',
    'sell-payment-succeed': 'فروش با موفقیت انجام شد',
    cancel: 'لغو',
    fees: 'کارمزدها',
    'user-levels': 'سطوح کاربری',
    'footer-info':
        'احترام اصیل و حفظ کرامت انسانی کاربر، در هر شرایطی سرلوحه مرام اخلاقی و حرفه‌ای کار ما است از این رو جهت افزایش کیفیت و بهره‌وری خدمات صرافی آماده پذیرش پیشنهادات و انتقادات شما می‌باشیم.',
    'animate-mobile-desc1': 'کاملترین مجموعه کوین و توکن',
    'animate-mobile-desc2': 'محیط کاربری حرفه‌ای',
    'animate-mobile-desc3': 'کیف‌پول اختصاصی و امن',
    'animate-mobile-title': 'به دنیای کنز ایکس خوش آمدید',
    'market-table-header': 'خرید و فروش دارایی‌های دیجیتال',
    'market-table-description': 'در جذاب‌ترین قیمت',
    'about-us-paragraph-1':
        'با رشد روزافزونِ گرایش مردم به خرید، معامله و نگهداری دارایی‌های دیجیتال، صرافی‌های متمرکز و غیر متمرکزِ بین المللیِ بسیاری گام در عرصه ارائه خدمات مالی به این دسته از افراد نهاده اند. سرمایه گذاری در حوزه ارزهای دیجیتال در ایران نیز با استقبال گسترده ای از سوی مردم مواجه شده است و ایرانیان بسیاری در صرافی‌های بین المللی مشغول به خرید و معامله این دارایی‌ها شده اند، اما متأسفانه بعلت محدودیت‌ها و تحریم‌های ظالمانه علیه کشورمان، روز به روز به مشکلات کاربران ایرانی این صرافی‌ها افزوده شده است و بسیاری از صرافی‌ها ارائه خدمات به اتباع ایرانی را ممنوع اعلام کرده اند و این مسأله ریسک سرمایه گذاری در این صرافی‌ها را برای ایرانیان به شدت افزایش داده است، به همین سبب جهت رفع موانع موجود بر سرِ راه سرمایه گذاری ایرانیان در این حوزه، ضرورت بومی سازی و ارائه خدمات به هموطنانمان در داخل کشور بیش از پیش احساس می‌شود.\n',
    'about-us-paragraph-2':
        'در این راستا کنز ایکس با اتکا به سرمایه‌های انسانی زبده ی کشورمان در حوزه‌های مالی و فناوری اطلاعات، جهت پاسخگویی به این نیازِ روزافزونِ کشورمان پا به عرصه وجود گذاشته است.\n',
    'about-us-paragraph-3':
        'مجموعه کارشناسان کنز ایکس با تحلیلِ وضعیتِ جاریِ این حوزه در کشورمان و با شناسایی نیازها و کاستی‌هایی که در پلتفرم‌های فعالِ کنونی در کشورمان وجود دارند، در صدد رفع این کاستی‌ها و مشکلات و ارائه هرچه بهتر خدمات به هموطنانمان برآمده است.\n',
    'about-us-paragraph-4':
        'علاوه بر مجموعه کاملی از ویژگی‌ها و ابزارهای معاملاتی پیشرفته، کنز ایکس دسترسی به بازارهای معاملات همتا به همتا (P2P) را جهت انتخاب طیف گسترده ای از دارایی‌های دیجیتال که عموماً در پلتفرم‌های فرابورس (OTC) عرضه نمی‌شوند را فراهم نموده است.\n',
    'about-us-paragraph-5':
        'با رشد پلتفرم‌های معاملاتی آنلاین در سطح دنیا و گسترش اقبالِ عمومی به سرمایه گذاری و فعالیت در این حوزه‌ها، مبحث پایداریِ زیرساخت‌ها و امنیت دارایی‌ها و داده‌های کاربران بیش از هر زمان دیگری به دغدغه اصلی کاربران و فعالان این عرصه تبدیل شده است.\n',
    'about-us-paragraph-6':
        'مجموعه مدیریتی کنز ایکس با درکِ این مهم، حساسیت و توجه ویژه ای را به رفعِ هر چه بهترِ این دغدغه کاربران معطوف نموده است؛ لذا با جذب بهترین متخصصان شبکه و امنیت سایبری و استفاده و بکارگیری آخرین تکنولوژی‌ها و راهکارهای روزِ دنیا، حتی‌المقدور سعی و کوشش خود را جهت تقلیلِ نگرانی‌هایِ بحقِ امنیتی کاربران مبذول داشته است.\n',
    'about-us-paragraph-7':
        'یکی از مهمترین ویژگی‌هایی که برای کاربران هر پلتفرمی اهمیت دارد، سرعت عمل در موتور تطبیق معاملات P2P و همچنین تسویه آنی برای آن دسته از کاربرانی است که قصد مبادله ارزهای فیات با ارزهای دیجیتال و بالعکس را دارند.\n',
    'about-us-paragraph-8':
        'بنابراین یکی از مواردی که مجموعه کنز ایکس همواره به آن اهتمام ویژه ای داشته است، انجام تراکنش‌های مالی کاربران در کمترین زمان ممکن است، این امر به میزانی برای مؤسسان کنز ایکس حائز اهمیت بوده است که در نامگذاریِ عنوانِ صرافی از واژه لاتین Neutrino  وام گرفته شده است!\n',
    'about-us-paragraph-9':
        'در کیهان نوترینوها ذرات بنیادیِ زیر اتمی ای هستند که دارایِ بارِ خنثی هستند و به ندرت با ماده و جریانات الکترومغناطیسی واردِ بَرهمکُنش می‌شوند، به همین سبب دارایِ سرعتی قریب به سرعت نور می‌باشند و تقریبا توانایی عبور از هر ماده ای را دارند. از این رو واژه نوترینو در ادبیات امروزی تبدیل به مترادفی برای مفهومِ "حداکثر سرعت" شده است و ما با استفاده از این واژه و ترکیب آن با \'ex\' که مخفف Exchange به معنای صرافی می‌باشد، به نحوی سعی در رساندن این پیام به کاربران گرامی داشته ایم که "سرعت عمل" در انجام امور، در صدرِ اولویت‌های این مجموعه است و همواره خواهد بود!\n',
    'about-us-paragraph-10':
        'در پایان، امید داریم تا به یاری و مهرِ ایزد منان و عنایتِ شما کاربران گرامی بتوانیم گامی سِتُرگ در جهتِ گسترش و ارتقاء کیفیِ سطحِ خدمت رسانی در این حوزه در میهن عزیزمان "ایران" برداریم.\n',
    commission: 'نمایندگی',
    'commission-paragraph':
        'از آنجاییکه هسته اصلی پورتال، قابلیت توسعه و ساپورت  چند زبانه را دارد و همچنین به دلیل فول نود بودن قابلیت تعریف کارنسی پایه متفاوت را دارد ، در صورت تمایل به دریافت نمایندگی ، درخواست و رزومه کامل خود را ایمیل نمایید',
    'contact-us-title-1': `آدرس حضوری`,
    'contact-us-paragraph-3': `021-91006555`,

    'terms-of-use-item-1-title': ``,
    'terms-of-use-item-1-paragraph-1': `لطفاً شرایط زیر را با دقت بخوانید زیرا این شرایط و مقررات بر استفاده شما از خدمات کنز ایکس حاکم است.
`,
    'terms-of-use-item-1-paragraph-2': `توافق‌نامه‌ی حاضر، اصول اولیه مانند حقوق، تکالیف و مسئولیت‌ها جهت استفاده کاربر از خدمات کنز ایکس برای معامله ارز‌های دیجیتال است.
`,
    'terms-of-use-item-1-paragraph-3': `این شرایط برای استفاده از سایت  و پلتفرمهای رسمی کنز ایکس است ، خدمات کنز ایکس به موارد مختلفی اطلاق می‌شود که کنز ایکس به شما ارائه می‌کند که مبتنی بر فناوری‌های اینترنت و یا بلاک‌چین هستند و از طریق وبسایت کنز ایکس، برنامه‌های تلفن همراه و سایر اشکال ارائه می‌شوند.
`,
    'terms-of-use-item-1-paragraph-4': `کنز ایکس تابع قوانین جمهوری اسلامی ایران بوده و بستری برای خرید و فروش و تبادل ارزهای دیجیتال می باشد. هیچ گونه تبادل ارزی اعم از خرید و فروش دلار و سایر ارزهای کاغذی در این بستر صورت نمی‌گیرد. کلیه‌ی فعالیت‌های کنز ایکس در چارچوب قوانین جمهوری اسلامی میباشد .کنز ایکس تابع قوانین جمهوری اسلامی ایران بوده و از همه‌ی مقررات حاکم بر آن تبعیت می‌کند.
`,
    'terms-of-use-item-1-paragraph-5': `مفاد توافق‌نامه از سوی ما ارائه می‌شود و پس از موافقت کاربر و تایید آن (با ثبت نام به‌عنوان کاربر) به اجرا گذاشته می‌شود.  همچنین در صورت لزوم از سوی ما امکان اصلاح قوانین وجود دارد. مسلماً اعمال هرگونه تغییر در این توافق‌نامه، قبل از اجرا به اطلاع کاربر خواهد رسید
`,
    'terms-of-use-item-2-title': `تعهدات کاربران کنز ایکس
`,
    'terms-of-use-item-2-list-item-1': `کاربران موظف اند کلیه مفاد این تعهدنامه را قبل از ثبت نام مطالعه کرده و صرفاً در صورت پذیرش کامل آن اقدام به ثبت نام نمایند. استفاده از خدمات کنز ایکس از قبیل (ثبت نام- خرید وفروش و...) به این معنی است که کاربر قوانین را کاملاّ خوانده، فهمیده و قبول کرده است، در غیر این صورت حق استفاده از خدمات سایت را ندارند.
`,
    'terms-of-use-item-2-list-item-2': `کاربران سایت می‌پذیرند که کلیه فعالیت‌های آنان در بسترهای کنز ایکس، در چهارچوب قوانین جمهوری اسلامی ایران بوده و هیچ گونه فعالیتی خارج از این چهارچوب انجام نخواهند داد.
`,
    'terms-of-use-item-2-list-item-3': `ایجاد هر گونه وبسایت، اپلیکیشن، خدمات اعم از تبلیغات به نام کنز ایکس یا استفاده از اطلاعات کسب شده از آن، بدون اجازه کتبی افراد دارای سمت ممنوع بوده و دارای پیگرد قانونی است. کنز ایکس مسئول این قبیل اقدامات متقلبانه در مقابل اشخاص ثالث نمی‌باشد.
`,
    'terms-of-use-item-2-list-item-4': `احراز هویت برای استفاده از خدمات کنز ایکس ضروری بوده و درصورت درخواست احراز هویت، کاربران موظف اند اطلاعات صحیح خود را بارگذاری نمایند. همچنین هر اطلاعاتی که در اختیار کنز ایکس قرار داده می‌شود به موقع به روز رسانی شود تا یکپارچگی و صحت اطلاعات حفظ شود. بدیهی است در صورت وجود هرگونه مغایرت در اطلاعات ارسال شده با هویت واقعی فرد، مسئولیت به عهده فرد متخلف بوده و کنز ایکس حق توقف ارائه خدمات به کاربر مذبور و ارجاع موارد تخلف به مراجع ذی صلاح را خواهد داشت. لازم به ذکر است انجام هرگونه اعمال متقلبانه و خلاف واقعیت توسط استفاده‌کنندگان از خدمات کنز ایکس به هرعنوان، دارای پیگرد قانونی خواهد بود.
`,
    'terms-of-use-item-2-list-item-5': `اگر شما به عنوان یک کارمند یا نماینده شخص حقوقی عمل کرده و از خدمات کنز ایکس استفاده می‌کنید، تضمین می‌کنید که تمام مجوزهای لازم را برای تعهد به آن شخص حقوقی دارید.
`,
    'terms-of-use-item-2-list-item-6': `تمامی معاملات انجام گرفته صحیح تلقی می‌شود، از این رو صیانت از حساب کاربری، رمز عبور و سایر اطلاعات شخصی به عهده شخص کاربر بوده و شایسته است به عواقب ناشی از انجام تخلف و جرایم مالی توجه داشته باشد. کنز ایکس مسئول هیچ گونه خسارت ناشی از معاملات حساب کاربر توسط شخص ثالث بدون اجازه وی نمی‌باشد.
`,
    'terms-of-use-item-2-list-item-7': `کاربران متعهد می‌شوند که تنها برای خود از خدمات کنز ایکس استفاده نمایند و به هیچ عنوان خرید یا فروش برای غیر انجام ندهند. بدیهی است در صورت تخلف مسئولیت هر گونه عواقب و مشکلات ناشی از استفاده غیر از حساب کاربری بر عهده کاربر می‌باشد. همچنین امکان تعلیق، مسدود و باطل کردن حساب در صورت استفاده شخص ثالث به غیر از کاربر برای رمزینکس محفوظ است.
`,
    'terms-of-use-item-2-list-item-8': `در صورت افشای اطلاعات محرمانه کاربر مانند افشای نام کاربری و رمز عبور، کاربران میبایست در اسرع وقت مراتب را به کنز ایکس اطلاع داده و درخواست مسدود کردن حساب کاربری خود را نیز بدهند. بدیهی است در صورت افشای اطلاعات محرمانه کاربر و عدم اطلاع آن، رمزینکس هیچ مسئولیتی در قبال سو استفاده افراد غیر نخواهد داشت و در صورت احراز بی احتیاطی کاربر و ورود خسارت به کنز ایکس وی مسئول جبران خسارت خواهد بود.
`,
    'terms-of-use-item-2-list-item-9': `تلاش ما ارائه‌ی خدمات به صورت ۲۴ ساعته در طول شبانه‌روز است. با وقوع حادثه‌ای از قبیل تعلیق خدمات به علت اقدام غیرقانونی کاربران یا اشخاص ثالث یا وقوع خطا در خدمات، ابتدا مشکل را رفع کرده و ارائه‌ی خدمات را از سر می‌گیریم. در این زمان می‌توانید درخواست بازیابی را بر اساس سوابق معاملاتی ارائه کنید. اما نمی‌توانیم سود و زیان ناشی از این وقفه زمانی را جبران کنیم.
`,
    'terms-of-use-item-2-list-item-10': `کاربران متعهد هستند نسبت به شناسایی و اطمینان یافتن از درستی آدرس و دسترسی کامل به کیف پول‌هایی که برای آن‌ها به عنوان کیف پول مقصد ارز ارسال می‌کنند اطمینان حاصل کنند، هرگونه مسئولیت ناشی از واریز و بروز خطا به کیف پول‌های ناشناس توسط کاربر به عهده وی می‌باشد.
`,
    'terms-of-use-item-2-list-item-11': `در صورت وقوع خسارت به کنز ایکس، در اثر فعالیت‌های غیر قانونی کاربر، کنز ایکس می‌تواند نسبت به مطالبه آن اقدام نماید.
`,
    'terms-of-use-item-2-list-item-12': `معاملات ارزهای دیجیتال باید با آگاهی و بررسی انجام شود و مسئولیت ضرر ناآگاهانه از خرید ارزهای دیجیتال بر عهده‌ی خود کاربر است . کاربران کنز ایکس اذعان می‌دارند که از ریسک‌های مربوط به سرمایه گذاری در ارزهای دیجیتال مطلع بوده و با علم به این موضوع اقدام به سرمایه گذاری و تبادل می نمایند. کنز ایکس هیچ گونه مسئولیتی در قبال نوسانات قیمت‌های ارز دیجیتال که متاثر از عرضه و تقاضا در بازارهای جهانی هستند، ندارند. همچنین معاملات ارزهای دیجیتال باید با آگاهی‌ و بررسی انجام شود، فلذا مسئولیت خرید و فروش ناآگاهانه بر عهده خود کاربر است و کنز ایکس مسئولیتی در قبال نحوه معاملات کاربران و سود و زیان حاصل از آن ندارد. همچنین قیمت بازار پول مجازی که توسط ما بعضاً اعلام می‌شود ممکن است به طور موقت با قیمت واقعی همخوان نباشد که علت آن محدودیت‌های فنی از قبیل خطای سیستمی یا برنامه‌ای عدم تقارن محیطی، محدودیت در مورد فضای ارتباطی در داخل و خارج است. البته ما تمامی تلاش خود را برای رفع این تفاوت در قیمت انجام می‌دهیم.
`,
    'terms-of-use-item-2-list-item-13': `کنز ایکس تلاش می‌کند تا اطلاعات ارسال شده در محتوای سرویس‌هایی که ارائه میکند تا حد ممکن صحیح باشند، اما نمی‌تواند تضمین کننده مناسب بودن این اطلاعات برای استفاده در انجام معاملات باشد و مسئولیت خسارات ناشی از استفاده‌ی چنین محتوایی بر عهده خود شما است. اطلاعات مربوط به خدمات می‌توانند بدون اطلاع قبلی تغییر کنند.
`,
    'terms-of-use-item-2-list-item-14': `کنز ایکس توصیه یا مشاوره‌ای در زمینه سرمایه ‌گذاری ارائه نمی‌دهد و هیچ گونه مسئولیتی در مورد استفاده یا تفسیر غلط از اطلاعاتی که در سرویس‌ها یا سایر رسانه‌های ارتباطی ارائه می دهد، نخواهد داشت. کلیه کاربران سرویس‌ها باید بدانند که خطرات بالقوه‌ای در معاملات ارزهای دیجیتال وجود دارد.
`,
    'terms-of-use-item-2-list-item-15': `کاربر تعهد می‌دهد که مالک قانونی وجوه و حساب‌هایی است که وی در هر سفارش به هر نحو چه برای پرداخت و چه برای دریافت از آنها استفاده کرده است.
`,
    'terms-of-use-item-2-list-item-16': `کاربر با استفاده از خدمات کنز ایکس می‌پذیرد در صورت نقض هر کدام از تعهدات یاد شده، کنز ایکس مجاز به انجام بررسی و اتخاذ تصمیم مقتضی ولو بدون موافقت کاربر باشد و در صورت نیاز هر اقدامی مانند تعلیق حساب کاربر، بستن اجازه سفارش گذاری، گزارش به مراجع ذی صلاح قانونی انجام دهد.
`,
    'terms-of-use-item-3-title': `مدیریت حساب
`,
    'terms-of-use-item-3-paragraph': `برای استفاده از خدمات کنز ایکس نیاز به یک حساب کاربری می‌باشد و کاربر موظف به ایجاد حساب از طریق اطلاعات صحیح می‌باشد که در این امر نباید از اطلاعات دیگران استفاده کرده یا قوانین و مقررات مربوطه را نقض نماید.
تأیید نکردن حساب کاربری توسط کنز ایکس
در موارد زیر می‌توانیم ایجاد حساب را مردود اعلام کرده و به حذف حساب اقدام نماییم:
`,
    'terms-of-use-item-3-list-item-1': `الف) چنانچه کاربر به سن قانونی ( 18 سال تمام شمسی ) نرسیده باشد یا اهلیت کافی برخوردار نباشد مگر با دخالت ولی خاص یا قیم قانونی
`,
    'terms-of-use-item-3-list-item-2': `ب) تلاش برای ایجاد حساب از طریق ارائه اطلاعات شخصی دیگران توسط کاربر از قبیل نام یا شماره تلفن یا پست الکترونیکی دیگری
`,
    'terms-of-use-item-3-list-item-3': `ج) عدم وارد کردن اطلاعات لازم یا اطلاعات نادرست توسط کاربر در زمان ایجاد حساب
`,
    'terms-of-use-item-3-list-item-4': `د) در صورت عدم رعایت قوانین ومقررات اعلام شده از سوی رمزینکس
`,
    'terms-of-use-item-3-list-item-5': ` حساب شما تنها باید توسط خود شما مورد استفاده قرار گیرد. در صورت تغییرات اطلاعات شما پس از ثبت نام، باید بلافاصله اطلاعات جدید را در اطلاعات حساب به روز کرده و منتظر تایید سایت در اسرع وقت باشید.
`,
    'terms-of-use-item-3-list-item-6': `در خصوص خسارات ناشی از عدم اصلاح اطلاعات یا صدمه‌ی ناشی از استفاده‌ی غیر مجاز از حساب توسط شخص دیگری که رمز عبور شما را به سرقت برده است کنز ایکس مسئولیتی نخواهد داشت.
`,
    'terms-of-use-item-3-list-item-7': `برای ارائه‌ی بهتر خدمات، ممکن است اعلامیه‌های مربوط به استفاده از خدمات، شامل انواع اطلاعات برای شما ارسال شوند. این اطلاعیه‌ها ممکن است مستقیم به ایمیل یا تلفن همراه شما ارسال شوند.
`,
    'terms-of-use-item-3-list-item-8': `می‌توانیم با نقض مقررات از سوی شما به طور موقت یا دائمی مانع استفاده‌ی شما از خدمات شویم
`,
    'terms-of-use-item-3-list-item-9': `در صورتی که کاربر اطلاعات ورود خود برای سامانه را از دست دهد، برای بازگرداندن اطلاعات به او، اطلاعاتی که بنا به صلاح‌دید ما است دریافت و در صورت تایید اطلاعات به فرد دسترسی داده خواهد ‌شد. اگر احراز هویت کامل نشود، دسترسی بازگردانده نمی‌شود.
`,
    'terms-of-use-item-3-list-item-10': `اگر از یک سرویس مشخص یا حساب کاربری خود برای مدت طولانی استفاده نکنید، ممکن است جهت امکان استفاده از خدمات از شما اطلاعات دیگری مطالبه کنیم. این احتمال زمانی که ۶ ماه از آخرین استفاده‌ی شما گذشته باشد بسیار بالا خواهد بود.
`,
    'terms-of-use-item-4-title': `کاربر می پذیرد که:`,
    'terms-of-use-item-4-list-item-1': `اطلاعاتی که کاربر در خلال سفارش یا ثبت نام در سایت وارد کرده است کاملاّ محفوظ خواهد ماند و به هیچ شخص یا سازمان دیگری ارائه نخواهد شد، مگر در راستای مطابقت عملکرد با قوانین و مقررات کشور، دستور از مراجع قضایی و اداری ذی صلاح و ذی ربط و یا درخواست پلیس فتای جمهوری اسلامی ایران. در این موارد هیچ گونه مسئولیت قانونی برای جبران خسارت یا موارد دیگر برای کنز ایکس وجود ندارد و کاربران با استفاده از خدمات سایت ما اعلام رضایت خود را صراحتاً اعلام می‌دارند.
`,
    'terms-of-use-item-4-list-item-2': `این حق برای کنز ایکس محفوظ است که اطلاعات لازم برای احراز هویت را قبل از پذیرش از کاربر بخواهد. در این حال تا احراز هویت کامل به عمل نیامده است، عضویت کاربر امکان نخواهد داشت. فرآیند احراز هویت شامل گردآوری اطلاعاتی از کاربر من جمله شماره تماس ثابت ، آدرس، کارت ملی با تصویر آن، کد ملی و اطلاعات بانکی می باشد.
`,
    'terms-of-use-item-4-list-item-3': `این اقدامات حریم شخصی وی را نقض نخواهد کرد و به هیچ شخص ثالثی ارائه نخواهد شد.
`,
    'terms-of-use-item-4-list-item-4': `تصمیم راجع به موفق یا ناموفق بودن احراز هویت فقط در اختیار کنز ایکس خواهد بود و سایت کنز ایکس می‌تواند یک احراز هویت را به تشخیص خود قبول کند یا قبول نکند.
`,
    'terms-of-use-item-4-list-item-5': `منبع و مقصد همه‌ی ارزهای دیجیتال در تراکنش‌های او در سایت باید کاملاً قانونی و مطابق با قوانین بین‌المللی و مقررات جمهوری اسلامی ایران باشد.
`,
    'terms-of-use-item-4-list-item-6': `مالک قانونی وجوه و حساب‌هایی است که در هر سفارش به‌هر نحو (پرداخت و دریافت) از آن‌ها استفاده کرده است.
`,
    'terms-of-use-item-4-list-item-7': `تراکنش‌هایی که توسط کنز ایکس در خلال انجام سفارشات انجام می‌شود غیر قابل بازگشت هستند و تابع قوانین سیستم هر ارز دیجیتال مربوط به تراکنش انجام شده هستند.
`,
    'terms-of-use-item-4-list-item-8': `کاربر اطلاعات لازم در طی سفارش را با دقت وارد کرده است و کلیه ی مسئولیت مربوط به اشتباه وارد کردن اطلاعات در خلال سفارش با وی است و کنز ایکس مسئولیتی راجع به سفارش انجام شده‌ای که اطلاعات اشتباه داشته و موجب زیان کاربر شده نمی‌پذیرد. همچنین رمزینکس صرفا مسئول جابه جایی مقدار مشخص شده در تراکنش است.
`,
    'terms-of-use-item-4-list-item-9': `کارمزد خدمات کنز ایکس ، همانطور که در وبسایت رسمی اعلام شده است، دریافت می‌شود. کنز ایکس می‌تواند در هر زمان مبلغ کارمزد را به روز رسانی کرده و از حساب کاربر متناسب با معاملات و تراکنش‌های وی کسر نماید.
`,
    'terms-of-use-item-4-list-item-10': `هر گونه کارمزد –کمیسیون و هزینه‌ی دیگر که سیستم بانکی جهت نقل و انتقال پول به کاربر اضافه نماید، بر عهده‌ی کاربر خواهد بود.
`,
    'terms-of-use-item-4-list-item-11': `کنز ایکس هیچ گونه مسئولیتی راجع به تأخیر یا تراکنش ناموفق ایجاد شده در انجام سفارش به علت نقص یا مشکل یا تعمیرات سیستم ارز دیجیتال یا بانک پذیرنده ندارد.
`,
    'terms-of-use-item-4-list-item-12': `هر گونه پیامدهای مالیاتی ناشی از تراکنش‌های کاربران به عهده‌ی خود آنان خواهد بود.
`,
    'terms-of-use-item-4-list-item-13': `در صورت تعلیق حساب، تحقیقات اولیه در مورد حساب انجام داده و موظف خواهید بود که با ما همکاری کامل به عمل آورید.
`,
    'terms-of-use-item-4-list-item-14': ` در صورت تعلیق حساب، نمی‌توانید ریال را سپرده ‌گذاری کرده یا درخواست واریز دهید و نمی‌توانید از کیف پول خود استفاده کنید.
`,
    'terms-of-use-item-4-list-item-15': `کنز ایکس می‌تواند کلیه مبادلات و برداشت‌ها را در شرایط ذیل ممنوع کرده و البته ممنوعیت و علت آن را به شما اعلام خواهیم کرد:
`,
    'terms-of-use-item-4-list-item-16': `الف) چنانچه درخواست کتبی از محاکم و نهادهای دولتی در این خصوص ارائه شود.
`,
    'terms-of-use-item-4-list-item-17': `ب) در صورتی که مبلغ مبادله بسیار زیاد باشد.
`,
    'terms-of-use-item-4-list-item-18': `ج) در صورتی که کاربر مرتکب جرم شده یا مظنون به تملک عواید از محل ارتکاب جرم باشد.
`,
    'terms-of-use-item-4-list-item-19': `د) در سایر موارد چنانچه محدود کردن استفاده از خدمات پرداخت بر اساس سیاست عملیاتی ما ضروری تشخیص داده شود.
`,
    'terms-of-use-item-4-list-item-20': `اگر کنز ایکس یا من غیر حق، وجوه یا رمزارزی را به حساب کاربر منظور یا در محاسبات خود هرنوع اشتباهی نماید، تحت هر عنوان و بدون انجام هیچ گونه تشریفات اداری و قضائی و دریافت اجازه کتبی از متعهد وصاحب حساب، در هر زمان مجاز و مختار است راسا به رفع اشتباه و برداشت از حساب‌های وی اقدام نماید و تشخیص کنز ایکس نسبت به وقوع اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب معتبر خواهد بود و کاربر حق هیچ گونه اعتراض و ادعایی در خصوص نحوه عملکرد کنز ایکس نخواهد داشت.
`,
    'terms-of-use-item-4-list-item-21': `کاربر موظف است از وجوه حاصل از فعالیت قانونی اقدام به انجام معاملات در پلتفرم نماید. درصورتیکه وجوه مورداستفاده به هر دلیلی مستحق للغیر درآید ازجمله آنکه وجوه حاصل از عملیات مجرمانه، پولشویی و کلاهبرداریهای اینترنتی و فیشینگ به دست آید کاربر و یا صاحب حساب کاربری مکلف به جبران زیانها و خسارات کنز ایکس و سایر کاربران زیاندیده پلتفرم هست و هرگونه ادعای جهل و بی اطلاعی رافع مسئولیت نخواهد بود.
`,
    'terms-of-use-item-4-list-item-22': ` درصورت بروز هرگونه مشکل یا ابهام در هر یک از معاملات، کنز ایکس حق دارد مستقلا مبالغ طرفین را به حساب خودشان عودت دهد. در این صورت کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد کنز ایکس از هر جهت از خود ساقط می‌نماید.
`,
    'terms-of-use-item-4-list-item-23': ` واریزهای تحت بلاکچین به کنز ایکس باید تحت شبکه‌ی اصلی و نه تست باشد و همچنین توکن‌ها می‌بایست توکن‌های اصلی و تأیید شده توسط شبکه‌اش باشد و در صورت اشکال فنی و یا هرگونه مسأله‌ای که ارز اصلی به کنز ایکس انتقال داده نشود و کنز ایکس به اشتباه آن را تایید نماید کاربر موظف به انتقال ارز اصلی و جبران تمامی خسارات مربوطه و تسویه است.
`,
    'terms-of-use-item-4-list-item-24': `کاربر باید حین واریز ارز دیجیتال به قرارداد توکن مربوطه و شبکه‌ی مربوطه توجه کند و در صورتی که اشتباه کند ممکن است دارایی که منتقل کرده از دست بدهد.
`,
    'terms-of-use-item-4-list-item-25': `به منظور پیروی از قوانین ضد پولشویی انجام فرآیند احراز هویت به نحوی که در سایت کنز ایکس توضیح داده شده است ضروری می‌باشد.
`,
    'terms-of-use-item-4-list-item-26': `کنز ایکس این حق را برای خود محفوظ می‌دارد که شرایط و مقررات خود را در هر زمان که بخواهد و با اختیار و صلاح دید خود تغییر دهد و کاربران را از این تغییرات آگاه می‌سازد. بدیهی است که استفاده مداوم از خدمات کنز ایکس به عنوان پذیرش قوانین اصلاح شده تلقی خواهد شد. اگر با هر گونه اصلاح و تغییر شرایط موافق نیستید، باید استفاده از خدمات کنز ایکس را متوقف کنید. ما به شما توصیه می‌کنیم که بارها و بارها شرایط را مرور کنید تا اطمینان حاصل کنید که مقررات و ضوابط اعمال شده برای دسترسی و استفاده از خدمات را کاملاّ درک کرده‌اید.
`,
    'terms-of-use-item-4-list-item-27': `خدمات معامله‌ی کنز ایکس نقش واسطه را میان کاربران بر عهده دارد و ارزش و یا بازپرداخت پول مجازی را به هیچ‌وجه تضمین نمی‌کند. مسئولیت منافع اقتصادی نیز ناشی از معامله با واحد پول مجازی برعهده‌ی کاربر است. قیمت بازار پول مجازی که توسط ما اعلام می‌شود ممکن است به طور موقت با قیمت واقعی هم‌خوان نباشد که علت آن محدودیت‌های فنی از قبیل خطای سیستمی یا برنامه‌ای عدم تقارن محیطی، محدودیت در مورد فضای ارتباطی در داخل و خارج است. البته تمام تلاش خود را برای رفع این تفاوت در قیمت انجام می‌دهیم.
`,
    'terms-of-use-item-4-list-item-28': `ما صحت اطلاعات شخصی کاربر را که نزد ما است تضمین نمی‌کنیم. به محض اطمینان از عدم صحت آن موظف به قطع استفاده‌ی کاربر مربوطه از خدمات کنز ایکس خواهیم بود و اقدام متقابل را انجام می‌دهیم
`,
    'terms-of-use-item-4-list-item-29': `ما درباره‌ی خسارت‌های ناشی از سرقت حساب شما توسط شخص ثالث مسئولیتی نخواهیم داشت
`,
    'terms-of-use-item-4-list-item-30': `حداقل حجم معاملات در بازارهای کنز ایکس توجه فرمایید. معامله‌ای کمتر از حجم اعلام شده ثبت نمی‌شود.
`,
    'terms-of-use-item-4-list-item-31': `اولین درخواست برداشت رمز ارز ۴۸ ساعت بعد از اولین خرید امکان پذیر است. بدیهی است این محدودیت صرفا برای اولین درخواست اعمال می‌شود و بعد از آن محدودیتی وجود نخواهد داشت.
`,
    'terms-of-use-item-4-list-item-32': `در صورت تعلیق حساب، ما درباره‌ی حساب انجام تحقیقات خواهیم کرد و شما موظف هستید که با ما در این مسیر همکاری کنید.
`,
    'terms-of-use-item-4-list-item-33': `در صورت تعلیق حساب، نمی‌توانید ریال را سپرده‌گذاری کنید یا درخواست واریز داشته باشید. همچنین نمی‌توانید از کیف پول خود استفاده کنید.
`,
    'terms-of-use-item-4-list-item-34': `همواره می‌توانید پول نقد معادل ریال خود را که در کیف پول حساب شماست، دریافت کنید. این مسئله به تعیین آن از سوی ما بستگی خواهد داشت. کنز ایکس تعهدی درباره‌ی پرداخت سود مربوط برای ریال موجود در کیف الکترونیکی شما ندارد.
`,
    'terms-of-use-item-4-list-item-35': `می‌توانیم مبادله‌ی ریال را در شرایط ذیل ممنوع کنیم. ممنوع شدن و علت آن را به شما اعلام خواهیم کرد.
`,
    'terms-of-use-item-4-list-item-36': `الف ـ اگر درخواست کتبی از محاکم و نهادهای دولتی ارائه شود.
`,
    'terms-of-use-item-4-list-item-37': `ب ـ در صورتی که مبلغ مبادله بسیار زیاد باشد.
`,
    'terms-of-use-item-4-list-item-38': `ج ـ در صورتی که کاربر مرتکب جرم شده باشد یا مظنون به تملک عواید از محل ارتکاب جرم باشد.
`,
    'terms-of-use-item-4-list-item-39': `دـ در سایر موقعیت‌هایی که محدود کردن استفاده از خدمات پرداخت بر اساس سیاست عملیاتی ما ضروری تشخیص داده شود.
`,
    'terms-of-use-item-4-list-item-40': `۶ـ سایر موارد
`,
    'terms-of-use-item-5-title': `سایر موارد
`,
    'terms-of-use-item-5-paragraph': `کنز ایکس در حدود قانون هیچگونه تعهد یا ضمانتی برای اموری خاص مسکوت مانده در این موافقنامه از حیث ارائه‌ی خدمات بر عهده نمی‌گیرد. علاوه بر این، در خصوص محصولات یا ارزش واحد پول مجازی که خود آن را صادر نکرده یا واگذار ننموده و همچنین پرداخت آن را ضمانت ننموده‌ایم، هیچ گونه تعهدی نخواهیم داشت:
`,
    'terms-of-use-item-5-list-item-1': `در صورتی که خدمات در اثر اقدام شما دچار مشکل شود.
`,
    'terms-of-use-item-5-list-item-2': `خسارات ناشی از فرآیند ورود به خدمات و استفاده از آن.
`,
    'terms-of-use-item-5-list-item-3': `خسارات ناشی از دسترسی غیر قانونی شخص ثالث به سرور و استفاده غیر قانونی از آن
`,
    'terms-of-use-item-5-list-item-4': `ضررهای ناشی از انتقال و انتشار بد افزارها از سوی شخص ثالث.
`,
    'terms-of-use-item-5-list-item-5': `ضررهای ناشی از استفاده شخص ثالث از خدمات، خساراتی ناشی از دور زدن، حذف یا نابودی اطلاعات ارسال شده.
`,
    'terms-of-use-item-5-list-item-6': `ضررهای ناشی از قصور در خدمات ارتباطاتی توسط بستر ارائه خدمات. به ویژه مواردی که ارائه دهنده خدمات ارتباطاتی در صورت وجود رابطه حقوقی فی ما بین ما و کاربران موجب ناتوانی ما در ارائه‌ی خدمات شود و غیر قابل کنترل باشد. ضررهای ناشی از موارد قهریه از قبیل جنگ، آتش سوزی، زلزله و سایر بلایای طبیعی و موارد اضطراری ملی ضررهای ناشی از ویژگی‌های خاص پول مجازی. به ویژه مواردی که موجب نقص یا محدودیت فنی در سیستم مدیریت در ارائه‌ی خدمات از سوی ما می‌شود.
`,
    'terms-of-use-item-5-list-item-7': `در صورتی که سرورها را برای ارائه‌ی خدمات قطع شود یا از دسترس خارج شوند.
`,
    'terms-of-use-item-5-list-item-8': `سایر خسارات ناشی از دلایلی که ناشی از اعمال یا عمل ما نباشد.
`,
    'terms-of-use-item-5-list-item-9': `اگر اطلاعات ارائه شده از سوی شما با واقعیت هم‌خوانی نداشته باشند، می‌توانیم ارائه‌ی خدمات را متوقف یا تمامی یا بخشی از این توافق‌نامه را فسخ کنیم. در صورتی که خسارتی متوجه ما شود، می‌توانیم خسارت را از حساب شما برداشت کنیم.
`,
    'terms-of-use-item-5-list-item-10': `در صورتی که ما باعث خسارت شما شده باشیم و ما این خسارت را مطالبه کنیم، می‌توانیم این خسارت را از طریق پول مجازی ریالی به کیف الکترونیکی شما واریز کنیم؛ حتی اگر شما با این روش موافق نباشید.
`,
    'terms-of-use-item-5-list-item-11': `در صورت ورود خسارت به ما که ناشی از فعالیت‌های غیر قانونی است ما حق مطالبه‌ی خسارت علیه شما به موجب قانون را برای خود محفوظ می‌دانیم. لذا لازم است از خدمات در چارچوب قوانین و مقررات استفاده کنید.
`,
    'terms-of-use-item-5-list-item-12': `در صورت حملات هکری، سایبری و ویروسها که منجر به نشر اطلاعات و نفوذ امنیتی در سامانه‌های نرم افزاری گردد، بنحوی که فراتر از تدابیر امنیتی استاندارد برای حفظ امنیت باشد، کنز ایکس تمام تلاش خود را برای رفع این مشکلات خواهد نمود اما مسئولیتی در این قبال آثار خرابکارانه این حملات ندارد.
`,
    'terms-of-use-item-5-list-item-13': `همچنین در صورت انسداد حسابهای بانکی کنز ایکس به دستور مقام قضائی و یا مشکلات فنی سیستم پرداخت کشور تا رفع این انسداد کنز ایکس مسئولیتی از جهت پرداخت خسارت به کاربران ندارد.
`,
    'terms-of-use-item-6-title': `حل اختلاف
`,
    'terms-of-use-item-6-list-item-1': `ابتدا با کنز ایکس تماس حاصل فرمایید. کنز ایکس می‌خواهد در صورت امکان نگرانی‌های شما را بدون توسل به مراحل قانونی رسمی برطرف کند. کنز ایکس تلاش می‌کند تا در اسرع وقت اختلافات شما را به صورت داخلی حل کند. همچنین طرفین توافق می‌کنند که با حسن نیت برای حل اختلاف مذاکره کنند.
`,
    'terms-of-use-item-6-list-item-2': `در صورتی که اختلاف را نتوان به صورت رضایت بخش حل و فصل کرد و شما می‌خواهید یک ادعای حقوقی علیه کنز ایکس مطرح کنید، موافقت می‌کنید که مبنای این ادعا را با عنوان ( اعلامیه ادعای حقوقی) به عنوان اطلاع قبلی به کنز ایکس اعلام کنید که در این اعلامیه باید ماهیت و مبنای اختلاف را شرح دهید، جبران درخواستی خود را بیان کرده و به آدرس legal@tokpenz.com ارسال کنید، این اعلامیه پیش نیاز شروع هرگونه دادرسی داوری و قانونی خواهد بود.
`,
    'terms-of-use-item-6-list-item-3': `ده روز پس از وصول اعلامیه، کنز ایکس در صورت نیاز شخص مدعی را برای حل و فصل اختلاف توسط داوری دعوت می‌نماید، شخص مدعی ملزم به حضور و توجه به نظر داور ( کارشناس متخصص آن حوزه که توسط کنز ایکس معرفی شده) می‌باشد و نتیجه صورت جلسه خواهد شد.
`,
    'terms-of-use-item-6-list-item-4': `در صورت تمایل برای شروع دادرسی قانونی پس از طی مراحل فوق الذکر، به علت اقامت داشتن کنز ایکس در تهران، دادگاه تهران منحصراً صالح به رسیدگی خواهد بود و جبران ضرر و زیان بر عهده محکوم علیه می‌باشد.
`,
    'terms-of-use-item-7-title': `تغییر در قوانین
`,
    'terms-of-use-item-7-paragraph': `هرگونه تغییر در شرایط و مقررات مشابه، از طریق تارنمای رسمی کنز ایکس به شما کاربران گرامی‌ اطلاع‌رسانی خواهد شد.
`,

    'seo-unit': `یکی از مهمترین ویژگی‌هایی که برای کاربران هر پلتفرمی اهمیت دارد، سرعت عمل در موتور تطبیق معاملات P2P و همچنین تسویه آنی برای آن دسته از کاربرانی است که قصد مبادله ارزهای فیات با ارزهای دیجیتال و بالعکس را دارند. بنابراین یکی از مواردی که مجموعه کنز ایکس همواره به آن اهتمام ویژه ای داشته است، انجام تراکنش‌های مالی کاربران در کمترین زمان ممکن است، این امر به میزانی برای مؤسسان کنز ایکس حائز اهمیت بوده است که در نامگذاریِ عنوانِ صرافی از واژه لاتین Neutrino وام گرفته شده است! در کیهان نوترینوها ذرات بنیادیِ زیر اتمی ای هستند که دارایِ بارِ خنثی هستند و به ندرت با ماده و جریانات الکترومغناطیسی واردِ بَرهمکُنش می‌شوند، به همین سبب دارایِ سرعتی قریب به سرعت نور می‌باشند و تقریبا توانایی عبور از هر ماده ای را دارند. از این رو واژه نوترینو در ادبیات امروزی تبدیل به مترادفی برای مفهومِ "حداکثر سرعت" شده است و ما با استفاده از این واژه و ترکیب آن با 'ex' که مخفف Exchange به معنای صرافی می‌باشد، به نحوی سعی در رساندن این پیام به کاربران گرامی داشته ایم که "سرعت عمل" در انجام امور، در صدرِ اولویت‌های این مجموعه است و همواره خواهد بود!`,

    'fees-paragraph-1': `هدف ما ایجاد بستری شفاف برای معاملات شماست. به همین منظور تمامی هزینه‌ها و کارمزدهای بستر کنز ایکس را به صورت شفاف و دقیق به اطلاع کاربران می‌رسانیم. ثبت‌نام و استفاده از سامانه‌ی کنز ایکس کاملا رایگان می‌باشد و تنها طبق توضیحات مندرج در این صفحه، از برخی معاملات و اعمال مالی انجام شده در سامانه هزینه‌ی کمی به عنوان کارمزد کسر می‌شود.
`,
    'fees-paragraph-2': `کارمزد معاملات در کنز ایکس بصورت درصد از مبلغ تراکنش و از هر دو طرف معامله یعنی سفارش دهنده (maker) : کاربری که ابتدائا در کنز ایکس سفارش ثبت می‌کند و سفارش پذیرنده (taker) : کاربری که سفارش موجود در سامانه را تکمیل کرده و سفارش نهایی می‌شود دریافت میگردد که این کارمزدها با یکدیگر برابر نیستند. 
`,
    'fees-paragraph-3': `کارمزد به صورت درصد از حجم دارایی درخواستی محاسبه می‌شود. به طور مثال اگر به عنوان فروشنده، بخواهید در برابر بیت‌کوین، ریال دریافت کنید کارمزد به صورت درصد از ریال دریافت می‌شود، و بالعکس اگر به عنوان خریدار بخواهید با ریال خود بیت‌کوین خریداری نمایید، کارمزد به صورت درصد از بیت‌کوین دریافت خواهد شد.
`,
    'fees-paragraph-4': ` با توجه به حجم معاملات یک ماه گذشته کاربران، کارمزدها مطابق جدول زیر تعیین می‌شود:
`,
    '1monthTrades': `حجم معاملات 30 روز گذشته

`,
    markerFee: `کارمزد Maker`,
    takerFee: `کارمزد Taker`,
    'less-than-50-millions': `کمتر از 50 میلیون

`,
    'between-50-to-200-millions': `بین 50 تا 200 میلیون

`,
    'between-200-to-500-millions': `بین 200 تا 500 میلیون

`,
    'more-than-500-millions': `بیش از 500 میلیون

`,
    'fees-paragraph-5': `کارمزد معامله‌های بازار پایه تتری به صورت ثابت کارمزد Maker 0.15% و کارمز Taker 0.2% در نظر گرفته میشود.
`,
    'fees-title-1': `کارمزد واریز و برداشت رمز ارز
`,
    'fees-paragraph-6': `صرافی کنز ایکس برای واریز و برداشت و در کل انتقال رمز ارزها هیچ‌گونه کارمزدی دریافت نمی‌کند و تمامی کارمزدها متعلق به شبکه بلاکچین است و خارج از اختیار کنز ایکس است.
`,
    'fees-title-2': `کارمزد واریز و انتقال رمز ارز به کنز ایکس
`,
    'fees-paragraph-7': `در صورت واریز و انتقال رمز ارز از مبدأ به مقصد که همان کنز ایکس باشد، کارمزد مورد نظر از سوی مبدأ از شما کسر خواهد شد.
`,
    'fees-title-3': `کارمزد برداشت رمز ارز از کنز ایکس
`,
    'fees-paragraph-8': `کارمزدهای برداشت بر اساس کارمزدهای جهانی شبکه بلاکچین رمز ارزها محاسبه شده است و برای هریک از رمزها متفاوت خواهد بود.
`,
    search: 'جستجو',
    'unit-price': 'قیمت هر واحد',
    'minimum-amount': 'حداقل مقدار',
    'maximum-amount': 'حداکثر مقدار',
    accountOverview: 'حساب کاربری',
    transactions: 'تراکنش ها',
    accountActivity: 'فعالیت حساب',
    twoFacAut: 'تنظیمات ورود دو مرحله ای',
    referFriends: 'معرفی دوستان',
    exit: 'خروج',
    balance: 'موجودی',
    'otc-trade-1': 'سفارش سریع 1',
    'otc-trade-2': 'سفارش سریع 2',
    wallet: 'کیف پول',
    dashboard: 'داشبورد',
    tooman: 'تومان',

    'balance-error': 'کاربر گرامی، موجودی شما برای این عملیات کافی نمی باشد.',
    'balance-btn': 'افزایش موجودی',
    'min-part-1': 'کاربر گرامی، حداقل مبلغ در هر معامله',
    'min-part-2': ' است.',
    'max-part-1': 'کاربر گرامی، حداکثر مبلغ در هر معامله',
    'max-part-2': ' است.',
    'max-btn': 'متوجه شدم',
    'min-btn': 'متوجه شدم',
    'buy-price': 'قیمت خرید',
    'sell-price': 'قیمت فروش',
    'select-coin': 'انتخاب ارز',
    volume: 'حجم',
    'buy-request': 'درخواست خرید',
    'sell-request': 'درخواست فروش',
    'otc-sub-1': 'آسان',
    'otc-sub-2': 'بخر ،',
    'otc-sub-3': 'امن',
    'otc-sub-4': 'نگهداری کن ،',
    'otc-sub-5': 'سریع',
    'otc-sub-6': 'بفروش',
    '24-support': 'پشتیبانی 24 ساعته',
    'even-holidays': 'حتی روزهای تعطیل',
    'blog-head': 'آخرین اخبار',
    newest: 'جدیدترین ها',
    hottest: 'داغ ترین ها',
    favest: 'محبوب ترین ها',
    favorites: 'مورد علاقه‌ها',
    profitest: 'بیشترین سود',
    lostest: 'بیشترین ضرر',
    'trade-crypto': 'معامله ارز دیجیتال',
    chart: 'نمودار',
    'faq-title-1': 'چطور دارایی دیجیتال داشته باشیم؟',
    'faq-description-1': 'ثبت نام در کنز ایکس در کمتر از ۵ دقیقه',
    'faq-desc-1':
        'فرآیند ثبت نام و احراز هویت اولیه در کنز ایکس بسیار سریع است. برای دسترسی به کیف پول و بازارها کافی‌ است ثبت نام اولیه را انجام دهید.',
    'faq-description-2': 'تکمیل مراحل احراز هویت (KYC)',
    'faq-desc-2':
        'انجام مراحل احراز هویت، دسترسی کامل به تمام امکانات و شرکت در عرضه‌های اولیه صرافی را برای شما فراهم می‌کند.',
    'faq-description-3': 'ورود به بازار و ثبت سفارش خرید و فروش',
    'faq-desc-3':
        'برای خرید و فروش ارزهای دیجیتال می‌توانید به بازار اختصاصی هر ارز وارد شوید و سفارش خود را ثبت کنید.',
    'faq-description-4': 'مدیریت سرمایه با استفاده از امکانات دابنکس',
    'faq-desc-4':
        'شما با پلتفرم کنز ایکس می‌توانید از کاملترین و حرفه‌ای‌ترین امکانات معامله‌گری جهت مدیریت دارایی خود استفاده کنید',
    'login-error': 'کاربر گرامی، برای انجام معاملات باید ورود کنید.',
    'login-btn': 'ورود',
    '24h-volume': 'حجم معاملات 24 ساعت',
    '24h-min': 'کمترین قیمت روز گذشته',
    '24h-max': 'بیشترین قیمت روز گذشته',
    'change-market': 'تغییر بازار',
    '24-change': 'تغییرات 24 ساعته',
    'i-pay': 'پرداخت می کنم',
    'i-receive': 'دریافت می کنم',
    'otc-price': 'قیمت هر واحد',
    'otc-min': 'حداقل مقدار معامله',
    'otc-max': 'حداکثر مقدار معامله',
    'buy-now': 'همین حالا میخرم',
    'sell-now': 'همین حالا میفروشم',
    'otc-trade-3': 'سفارش سریع 3',
    'otc-orders': 'تاریخچه سفارشات سریع',
    property: 'دارایی ها',
    'mi-wallet': 'کیف پول من',
    'manage-wallets': 'مدیریت کیف پول ها',
    'cards-accounts': 'مدیریت کارت‌ها و حساب‌ها',
    'transaction-history': 'تاریخچه تراکنش‌های بانکی',
    'dc-transaction-history': 'تاریخچه تراکنش‌های رمزارز',
    deposit: 'واریز',
    withdraw: 'برداشت',
    'current-orders': 'سفارشات جاری',
    orders: 'سفارشات',
    'orders-history': 'تاریخچه سفارشات',
    balance: 'موجودی',
    'balance-delay':
        'این مبلغ به صورت تخمینی میباشد و ممکن است در بروزرسانی داده ها،تاخیر وجود داشته باشد.',
    'balance-delay--2': 'این مبلغ به صورت تخمینی میباشد.',
    'see-all': 'مشاهده همه',
    'my-notifications': 'اعلان ها',
    'no-notification': 'هیچ اعلان جدیدی نیست...',
    'footer-phrase': 'جهانی بی مرز، معامله‌ای نامحدود',
    // head---------------------------------------------------------------
    'meta-description': "کنزایکس، بهترین صرافی برای خرید و فروش ارزهای دیجیتال با قیمت های رقابتی و امنیت بینظیر. همین حالا شروع کنید و به جمع سرمایه گذاران موفق بپیوندید!",
}

