import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
   @font-face {
        font-family: 'Vazir';
        src: url('/assets/fonts/Vazir/Vazirmatn-Medium.ttf');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Karsha_fa';
        src: url('/assets/fonts/karsha_fa.ttf');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
    font-family: 'Estedad';
    src: url('/assets/fonts/Estedad/Estedad-Medium.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'EstedadBold';
    src: url('/assets/fonts/Estedad/Estedad-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

    html {
        @media (max-width: 1024px) {
            font-size: 87.5%; // 14px
    }
        @media (max-width: 768px) {
            font-size: 75%;
        }
    }

   


    *, *::before, *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        font-family: 'Estedad','Vazir', sans-serif;
    }


    a {
        text-decoration: none;
    }

    input, button {
        outline: none;
    }

    ul {
        list-style: none;
    }
`
