import styled, { css } from 'styled-components'

const Navbar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-bottom: 1px solid ${(props) => props.theme.secondaryBg};
    height: 70px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #154a94;
    padding: 1rem 1.8rem;
    color: ${(props) => props.theme.color};

    @media (max-width: 1024px) {
        padding: 1rem;
    }
    @media (max-width: 768px) {
        justify-content: start;
    }
`

const Logo = styled.div`
    ${(props) =>
        props.header &&
        css`
            margin-inline-end: 2.5rem;
            display: flex;
            align-items: center;

            @media (max-width: 1024px) {
                margin-inline-end: 1rem;
            }
        `}

    a {
        display: inline-block;
        background: url('/assets/images/header/Logo.png') center cover;
    }
`

const NavLinkList = styled.ul`
    display: flex;
    align-items: center;
    gap: 1.2rem;

    @media (max-width: 1024px) {
        gap: 0.8rem;
    }

    & > li > a {
        padding: 0.5rem;
        font-size: 1rem;

        @media (max-width: 1024px) {
            font-size: 0.8rem;
        }
    }
`

const NavLinksWrapper = styled.div`
    display: flex;

    margin-inline-end: auto;
`

const NavButtonList = styled.div`
    display: flex;
    gap: 1rem;
    padding-inline: 1rem;

    @media (max-width: 768px) {
        gap: 0.5rem;
    }
`

const Button = styled.button`
    color: white;
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}

    ${(props) =>
        props.bg &&
        css`
            background-color: ${(props) =>
                props.bg === 'primary'
                    ? '#024796'
                    : 'rgba(255, 255, 255, 0.1)'};
        `}

    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    ${({ link }) =>
        link
            ? css`
                  & > a {
                      display: block;
                      padding: 0.5rem ${(props) => props.paddingX || '1rem'};

                      @media (max-width: 1024px) {
                          font-size: 0.9rem;
                          padding: 0.5rem 1rem;
                      }
                      @media (max-width: 768px) {
                          padding: 0.8rem 1rem;
                          font-weight: normal;
                      }
                  }
              `
            : css`
                  padding: 0.5rem ${(props) => props.paddingX || '1rem'};

                  @media (max-width: 1024px) {
                      font-size: 0.9rem;
                      padding: 0.5rem 1rem;
                  }
                  @media (max-width: 768px) {
                      padding: 0.8rem 1rem;
                      font-weight: normal;
                  }
              `}

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
        /* background-color: ${(props) => props.theme.hover}; */
    }
`

export { Navbar, Logo, NavButtonList, Button, NavLinkList, NavLinksWrapper }
