import axios from 'axios';
import { removeCookies } from 'cookies-next';
import { useEffect, useState } from 'react';
import { useMainContext } from '@/core/contexts/main';
import { useIsServerSide } from '@/core/hooks/common/useIsServerSide';
import { useRouter } from 'next/router';

const CrispProvider = ({ children }) => {
  const { token } = useMainContext();
  const { locale } = useRouter();
  const { isServerSide } = useIsServerSide();
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    if (token) {
      axios
        .get(process.env.NEXT_PUBLIC_API_ENDPOINT + 'profile', {
          headers: {
            'x-auth-token': token,
          },
        })
        .then((res) => {
          setProfile(res.data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401 && !isServerSide) {
            removeCookies('token');
            localStorage.removeItem('token');
          }
        });
    }
  }, []);

  useEffect(() => {
    const d = document;
    const s = document.createElement('script');
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '0871d276-cee6-43af-b2b4-decb6372b92e';
    window.CRISP_RUNTIME_CONFIG = {
      locale: locale,
    };
    (function () {
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      createCrisp();
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, []);

  useEffect(() => {
    if (token && !!profile) {
      profile.email && window.$crisp.push(['set', 'user:email', profile.email ? profile.email : '']);
      window.$crisp.push(['set', 'user:nickname', [profile._id]]);
      profile.avatar && window.$crisp.push(['set', 'user:avatar', [profile.avatar]]);
      profile.mobile && window.$crisp.push(['set', 'user:phone', [profile.mobile]]);
    }
  }, [token, profile]);

  return <>{children}</>;
};

export const createCrisp = () => {
  window.$crisp.push(['do', 'chat:hide']);
  window.$crisp.push(['config', 'color:theme', ['amber']]);
  window.$crisp.push(['on', 'chat:closed', () => window.$crisp.push(['do', 'chat:hide'])]);
};

export default CrispProvider;
