import { useMemo, useState } from 'react'
import {
    FaArrowAltCircleDown,
    FaArrowAltCircleUp,
    FaExchangeAlt,
    FaWallet,
} from 'react-icons/fa'
import { GiProfit } from 'react-icons/gi'
import {
    useWalletPLData,
    useWalletRealTimeData,
} from '@/core/hooks/wallet/useWalletData'

export const useWalletMainHook = () => {
    const mainTabs = [
        { title: 'realTime', Icon: FaWallet },
        { title: 'profit&Lost', Icon: <GiProfit></GiProfit> },
    ]
    const [mainTab, setMainTab] = useState('realTime')

    const currencyTabs = ['tooman', 'usdt']
    const [currencyTab, setCurrencyTab] = useState('tooman')

    const {
        userBalance,
        userChange,
        coinChange,
        areaChartData,
    } = useWalletPLData()

    const { myBalance } = useWalletRealTimeData()

    const type = currencyTab === 'tooman' ? 'irt' : 'usdt'

    const balanceData = useMemo(() => {
        if (mainTab === 'realTime') return myBalance
        else return userBalance
    }, [userBalance, myBalance, mainTab])

    const coinData = useMemo(() => {
        if (mainTab === 'realTime') return myBalance.coins
        else return coinChange
    }, [coinChange, myBalance, mainTab])

    const actionBtns = [
        {
            title: 'deposit',
            Icon: <FaArrowAltCircleUp color='#1ce087' size={18} />,
        },
        {
            title: 'withdraw',
            Icon: <FaArrowAltCircleDown color='#ce1440' size={18} />,
        },
        { title: 'trade', Icon: <FaExchangeAlt color='#ffc800' size={18} /> },
    ]

    const initialChooseModal = { type: null, open: false }
    const [chooseModal, setChooseModal] = useState(initialChooseModal)
    const openModal = (type) => setChooseModal({ type, open: true })
    const closeModal = () => setChooseModal(initialChooseModal)

    return {
        chooseModal,
        openModal,
        closeModal,
        actionBtns,
        mainTabs,
        mainTab,
        setMainTab,
        myBalance,
        areaChartData,
        coinData,
        // onAction,
        balanceData,
        type,
        currencyTab,
        setCurrencyTab,
        userChange,
        currencyTabs,
    }
}
