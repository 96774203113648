import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'

import Text from '@/components/common/utils/Text'
import { Logo } from '../header/styles/MainNav.styled'
import LogoImage from 'public/assets/images/footer/logo.svg'
import { ContactInfo } from './footer/ContactInfo'
import { useRouter } from 'next/router'
import { MainButton } from '@/components/common/buttons/MainButton'
import { routes } from '@/core/constants/routes'
import { HOME } from '@/core/constants/urls'
import CustomUnderline from '@/components/common/ui/CustomUnderline'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'

const NAVIGATIONS = [
    {
        label: 'footer_about',
        items: [
            {
                label: 'FIrtToTether',
                link: '',
            },
            {
                label: 'FContact',
                link: routes.contact,
            },
            {
                label: 'FAbout',
                link: routes.about,
            },
            {
                label: 'FGuide',
                link: routes.guide,
            },
            {
                label: 'FConflict',
                link: routes.conflict,
            },
            {
                label: 'blog',
                link: "https://blog.kenzx.io/",
            },
        ],
    },
    {
        label: 'footer_quick-access',
        items: [
            { label: 'FDictionary', link: routes.dictionary },
            { label: 'FFees', link: routes.fees },
            { label: 'FTerms', link: routes.terms },
            { label: 'deposit-and-withdraw', link: '/deposit-withdraw' },
            { label: 'FInvite', link: routes.invite, external: true },
            { label: 'FSecurity', link: '' },
            { label: 'FFaq', link: '/#faq' },
        ],
    },
    {
        label: 'footer_events',
        items: [
            { label: 'FNft', link: '' },
            { label: 'FStableCoin', link: '' },
            {
                label: 'FTransform',
                link: '',
            },
            {
                label: 'FServices',
                link: '',
            },
        ],
    },
]

export default function Footer() {
    const { width } = useWindowSize()
    const { locale: lang } = useRouter()
    const placeHolderText =
        lang === 'fa' ? 'ایمیل خود را وارد کنید' : 'Enter your Email'

    const renderedNavigations = NAVIGATIONS.map((item, index) => {
        const { label, items } = item

        return (
            <div key={index}>
                <CustomUnderline width={width > 768 ? '50%' : '20%'}>
                    <b className='text-xl mb-5 block fontBold'>
                        <Text tid={label} />
                    </b>
                </CustomUnderline>
                <ul className={'navigations'}>
                    {items.map((item, index) => {
                        const { label, link, external } = item

                        if (!!external) {
                            return (
                                <li
                                    key={index}
                                    className={
                                        'navigation-item transition-all hover:ms-1'
                                    }>
                                    <a href={HOME + link}>
                                        <Text tid={label} />
                                    </a>
                                </li>
                            )
                        }

                        return (
                            <li
                                key={index}
                                className={
                                    'navigation-item transition-all hover:ms-1'
                                }>
                                <Link href={link}>
                                    <Text tid={label} />
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    })

    return (
        <footer className='bg-blue-tertionary text-blue-primary-dark fontBold mb-[60px] md:mb-0 footer'>
            <div className='pt-24 px-0 lg:px-14 pb-8'>
                <FooterDetails>
                    <div
                        className={
                            'grid max-[480px]:grid-cols-1 grid-cols-3 lg:grid-cols-4 gap-8 text-sm'
                        }>
                        <div>
                            <Logo>
                                <Link href={'/'}>
                                    <Image
                                        src={LogoImage}
                                        width={width > 1050 ? 210 : 150}
                                        height={'100%'}
                                        alt='Limani EX'
                                    />
                                </Link>
                            </Logo>

                            <ContactInfo />
                        </div>
                        {renderedNavigations}
                    </div>

                    {/* <div className='border border-blue-primary-dark rounded-[10px] p-4 md:p-6 flex flex-col md:flex-row justify-between items-center'>
                    <h4 className='text-[20px] mb-2 md:mb-0'>
                        <Text tid={'footer_newsletter'} />
                    </h4>

                    <div className='bg-white p-4 rounded-[8px] w-full md:w-1/2 xl:w-[800px] flex justify-between'>
                        <Input
                            type='text'
                            placeholder={placeHolderText}
                            className='focus:ring-0'
                        />
                        <NewsBtn>
                            <Text tid={'footer_btn'} />
                        </NewsBtn>
                    </div>
                </div> */}

                    {/* <Divider className='bg-blue-primary-dark' /> */}
                </FooterDetails>
            </div>

            <p className='text-center mt-8 text-sm bg-blue-primary-dark text-blue-tertionary py-6'>
                <Text tid={'copyRight'} />
            </p>
        </footer>
    )
}
const FooterDetails = styled.div`
    max-width: 1240px;
    margin-inline: auto;

    display: flex;
    flex-direction: column;
    gap: 6rem;

    @media screen and (max-width: 1280px) {
        width: 90%;
    }
`

const Input = styled.input`
    width: 600px;
    padding: 10px 20px;
    border: none;
    outline: none;
    color: ${(props) => props.theme.pColor};

    @media screen and (max-width: 1280px) {
        width: 400px;
    }
    @media screen and (max-width: 480px) {
        padding: 0.5rem;
    }
`

const Divider = styled.div`
    width: 100%;
    height: 2px;
`

const NewsBtn = styled(MainButton)`
    padding: 10px 20px;
    color: #fff;

    @media screen and (max-width: 768px) {
        padding: 0.8rem 1rem;
    }
    @media screen and (max-width: 480px) {
        padding: 0.6rem 0.8rem;
        font-size: 0.9rem;
    }
`
