import MainFooter from '@/components/layout/main/MainFooter'
import Meta from '@/components/layout/Meta'
import { QueryClientProvider, Hydrate } from 'react-query'
import { useQueryContext } from '@/core/contexts/query'
import { useSetAxiosHeaders } from '@/core/hooks/axios'
import { ThemeProvider } from 'styled-components'
import { useMainContext } from '@/core/contexts/main'
import { getMainTheme } from '@/core/utils/theme'
import Toast from '@/components/common/utils/Toast'
import { MainBody } from '@/styles/styled'
// import Header from '@/components/layout/main/header';
import dynamic from 'next/dynamic'
import { AuthContextProvider } from '@/core/contexts/auth'
import LogoutLoading from '@/components/common/loading/LogoutLoading'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'
import BottomBar from '@/components/layout/BottomBar'
import MainNav from '../header/MainNav'

const SupportChat = dynamic(
    () => import('@/components/main/crisp/SupportChat'),
    {
        ssr: false,
    }
)

const MainLayout = ({ children, data = {}, pageProps }) => {
    useSetAxiosHeaders()
    const { queryClient } = useQueryContext()
    const { theme, lang } = useMainContext()
    const { width } = useWindowSize()

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <AuthContextProvider>
                    <Meta data={data} />
                    <Hydrate state={pageProps?.dehydratedState}>
                        <ThemeProvider theme={getMainTheme(theme, lang)}>
                            <MainNav />
                            <MainBody>{children}</MainBody>
                            <MainFooter />
                            <Toast />
                            {/* <SupportChat /> */}
                            <LogoutLoading />
                            {width < 768 && <BottomBar />}
                        </ThemeProvider>
                    </Hydrate>
                </AuthContextProvider>
            </QueryClientProvider>
        </>
    )
}

export default MainLayout
