export const enMessages = {
    APP_TITLE: process.env.NEXT_PUBLIC_APP_NAME_ENGLISH,
    'lorem-ipsum':
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    undefined: ' ',

    login: 'Login',
    register: 'Register',
    lang: 'Fa',
    'navbar-market': 'Market',
    'navbar-news': 'News',
    'navbar-blog': 'Blog',
    'navbar-guide': 'Guide',
    'navbar-about': 'About',
    'hero-title':
        'Welcome to the exciting world of digital currency with Limani EX!',
    'hero-paragraph':
        'From Bitcoin to Ethereum and other digital currencies, we provide the best resources and information for you here.',
    'trade-section-title': 'trade-section-title',
    'trade-section-description': 'trade-section-description',
    'register-now': 'Register Now',
    'trade-now': 'Trade Now',
    buy: 'Buy',
    sell: 'Sell',

    'feature__quantity--1': '+1200',
    'feature__quantity--2': '+5',
    'feature__quantity--3': '+76',
    'feature__title--1': 'people',
    'feature__title--2': 'million trades',
    'feature__title--3': 'currency',
    'feature__body--1': 'active users',
    'feature__body--2': 'highest trading volume',
    'feature__body--3': 'number of listed digital currencies',
    coin: 'Coin',

    'about-us__title--1': 'Find out more about Limani EX',
    'about-us__title--2': 'Who Are We?',
    'about-us__body':
        'TajBit Exchange Team" is a group of digital currency specialists with diverse experiences in technology, security, and e-commerce since 2020. TajBit Exchange Group has been active since late 2022 with the aim of providing a secure and efficient platform for transactions and investments in digital currencies. They continue their activities every day, striving to update and innovate in this field. Our mission: Our goal at TajBit Exchange is to create a safe and easily accessible space for new and professional users in the world of digital currencies. We invite interested investors and newcomers to join us in forming a constructive and dynamic community in this area.',
    'about-us__btn': 'learn more',

    'why-us__title': 'Why Limani Ex?',
    'why-us__body':
        'Our mission: Our goal at TajBit Exchange is to create a safe and easily accessible space for new and professional users in the world of digital currencies. We invite interested investors and newcomers to join us in forming a constructive and dynamic community in this area.',

    'markets-head': 'Market',
    'to-tooman': 'IRT',
    'to-tether': 'USDT',
    coins: 'Coins',
    trade: 'Trade',
    'daily-change': 'Daily changes',
    'volume-price': 'Volume (24h)',
    'see-more': 'See more',
    'see-less': 'See less',

    'apps__title--1': '',
    'apps__title--2': '',
    apps__body: '',
    apps__download__title: '',
    apps__download__body: '',

    'faq__title--1': 'Some questions you frequently ask',
    'faq__title--2': 'FAQ',
    'faq__tab--1__header': '',
    'faq__tab--2__header': '',
    'faq__tab--3__header': '',
    'faq__tab--4__header': '',
    'faq__tab--1__body': '',
    'faq__tab--2__body': '',
    'faq__tab--3__body': '',
    'faq__tab--4__body': '',

    guide__title: '',
    'guide__box--1__header': '',
    'guide__box--2__header': '',
    'guide__box--3__header': '',
    'guide__box--4__header': '',
    'guide__box--1__body': '',
    'guide__box--2__body': '',
    'guide__box--3__body': '',
    'guide__box--4__body': '',

    'get-started__title--1': '',
    'get-started__title--2__part1': '',
    'get-started__title--2__part2': '',
    'get-started__title--2__part3': '',
    'get-started__btn': '',

    // --------------------------------------------------------------------------------
    'you-pay': 'You pay',
    'you-receive': 'You receive',
    'do-exchange': 'Exchange',
    'last-price': 'Last Price',
    'daily-chart': 'Daily chart',
    'why-us.title-1': 'why-us.title-1',
    'why-us.description-1': 'why-us.description-1',
    'why-us.title-2': 'why-us.title-2',
    'why-us.description-2': 'why-us.description-2',
    'why-us.title-3': 'why-us.title-3',
    'why-us.description-3': 'why-us.description-3',
    'why-us.title-4': 'why-us.title-4',
    'why-us.description-4': 'why-us.description-4',
    'why-us.title-5': 'why-us.title-5',
    'why-us.description-5': 'why-us.description-5',
    'why-us.title-6': 'why-us.title-6',
    'why-us.description-6': 'why-us.description-6',
    'why-us.title-7': 'why-us.title-7',
    'why-us.description-7': 'why-us.description-7',
    'intro-title-1': 'intro-title-1',
    'intro-body-1-title-1': ' ',
    'intro-body-1-description-1': 'intro-body-1-description-1',
    'intro-title-2': 'intro-title-2',
    'intro-body-2-title-1': 'intro-body-2-title-1',
    'intro-body-2-description-1': 'intro-body-2-description-1',
    'intro-body-2-title-2': 'intro-body-2-title-2',
    'intro-body-2-description-2': 'intro-body-2-description-2',
    'intro-body-2-title-3': 'intro-body-2-title-3',
    'intro-body-2-description-3': 'intro-body-2-description-3',
    'intro-body-2-title-4': 'intro-body-2-title-4',
    'intro-body-2-description-4': 'intro-body-2-description-4',
    TOMAN: 'Toman',
    'guide-of-use': 'Guides of use',
    'home-page': 'Home',
    'how-to-register': 'How to register',
    'how-to-trade': 'How to trade',
    'about-us': 'About us',
    'terms-of-use': 'Terms of use',
    'customer-services': 'Customer services',
    FAQ: 'FAQ',
    'invite-friends': 'Invite your friends',
    'contact-us': 'Contact us',
    'footer-contact-us-info': 'footer-contact-us-info',
    'user-dashboard': 'User dashboard',
    'my-wallets': 'My wallets',
    'otc-trade': 'OTC Trade',
    markets: 'Markets',
    toman: 'Toman',
    buy: 'Buy',
    sell: 'Sell',
    'please-authenticate': 'Please login to your account',
    'sell-invoice': 'Sales Invoice',
    'buy-invoice': 'Purchase Invoice',
    unit: 'Unit(s)',
    fee: 'Fee',
    off: 'Discount',
    'off-over-fee': 'Discount on fee',
    'affiliate-profit': 'Affiliate profit',
    'amount-to-pay': 'Payable amount',
    'amount-to-receive': 'Receivable amount',
    'cancel-order': 'Cancel order',
    'go-on': 'Go on',
    'internal-wallet': 'Internal wallet',
    'external-wallet': 'External Wallet',
    'select-network': 'Select network',
    'internal-account': 'Internal account',
    'external-account': 'External account',
    'select-wallet': 'Select a wallet',
    'select-bank-account': 'Select an account',
    'no-banks-found': 'No bank accounts found',
    'banks-list': 'List of bank accounts',
    'no-wallets-found': 'No wallets found',
    'wallets-list': 'List of wallets',
    'invoice-popup-hint':
        'Please consider that the final amounts are calculated approximately.',
    'increase-balance': 'Increase balance',
    'not-enough-balance': 'Not enough balance',
    'withdraw-fee': 'Withdraw fee',
    'transfer-fee': 'Transfer fee',
    accountOverview: 'Account overview',
    transactions: 'Transactions',
    accountActivity: 'Activities',
    twoFacAut: 'Authentication',
    referFriends: 'Refer friends',
    exit: 'Exit',
    balance: 'Balance',
    'balance-error': 'Dear user, Your balance is insufficient.',
    'balance-btn': 'Increase balance',
    'min-part-1': 'Dear user, Min amount is ',
    'min-part-2': ' ',
    'max-part-1': 'Dear user, Max amount is ',
    'max-part-2': ' ',
    'max-btn': 'Got it',
    'min-btn': 'Got it',
    'buy-price': 'Buy price',
    'sell-price': 'Sell price',
    'otc-trade-1': 'OTC 1',
    'otc-trade-2': 'OTC 2',
    wallet: 'Wallet',
    dashboard: 'Dashboard',
    tooman: 'Tooman',
    volume: 'Volume',
    'buy-request': 'Buy Request',
    'sell-request': 'Sell Request',
    'otc-sub-1': 'Easy',
    'otc-sub-2': 'access, ',
    'otc-sub-3': 'Safe',
    'otc-sub-4': 'hold ,',
    'otc-sub-5': 'Fast',
    'otc-sub-6': 'trade',
    '24-support': '24/7 support',
    'even-holidays': 'Even holidays',
    newest: 'Newest',
    hottest: 'Hottest',
    favest: 'Most Favorite',
    profitest: 'Most Profit',
    lostest: 'Most Lost',
    'trade-crypto': 'Trade Crypto',
    chart: 'Chart',
    'login-error':
        'Dear user, You need to login in order to make OTC requests.',
    'login-btn': 'Login',
    'otc-trade-3': 'Otc 3',
    "my-notifications": "Notifications",
    "see-all": "See all",
    "no-notification": "No Notifications.",
}
