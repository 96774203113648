

/**
 * @param e - event
 * @param runAfterUpdate
 * @return {*}
 */
export const onInputValueChangeUtil = (e, runAfterUpdate) => {

    let value = p2e(e?.target?.value)
    value = value.replace( /[^\d.,]/g , '' )

    let newValue = ''
    let hasDot = false
    for (let i = 0; i < value.length; i++) {
        if (hasDot && value[i] === '.') continue
        if (value[i] === '.') {
            hasDot = true
        }
        newValue += value[i]
    }
    value = newValue

    let cursorStart = e?.target?.selectionStart
    let cursorEnd = e?.target?.selectionEnd
    let numParts = value.split('.')
    const originalLen = numParts[0].length
    numParts[0] = numParts[0].replaceAll(",", "")
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    const newLen = numParts[0].length

    runAfterUpdate(() => {
        if (newLen > originalLen) {
            cursorStart++
            cursorEnd++
        }else if (newLen < originalLen) {
            cursorStart--
            cursorEnd--
        }
        e?.target?.setSelectionRange(cursorStart, cursorEnd)
    })

    return (numParts.join("."))
}


const toPlainString = (num) => {
    return (''+ +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
        function(a,b,c,d,e) {
            return e < 0
                ? b + '0.' + Array(1-e-c.length).join(0) + c + d
                : b + c + d + Array(e-d.length+1).join(0);
        });
}


/**
 * Format numbers - filter
 * @param {number | float} num
 * @param {number} point
 * @param { string } type
 * @returns {string|number}
 */
export const formatNumber = (
    num,
    {point = 0, type= '', coin = ''} = {}
) => {
    if (!num) {
        return 0
    }
    // const float = parseFloat(num)

    let number = num.toString()
    if (number.includes('e')) number = toPlainString(num)
    const numParts = number.split('.')

    const firstPart = numParts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    // if (type === 'irt') return firstPart
    if (type === 'irt' && stringToNumber(firstPart) >= 10) return firstPart

    let secondPart = null;
    if (numParts.length > 1) {
        secondPart = numParts[1].toString().substring(0, point ? point : (type === 'usdt' ? 2 : 6))
    }

    let formattedNumber = firstPart
    if (secondPart) formattedNumber = `${firstPart}.${secondPart}`
    if (!secondPart && point) formattedNumber += '.00'
    if (point && secondPart) {
        for (let i = 0; i < point - secondPart.length; i++) {
            formattedNumber += '0'
        }
    }

    if (!point && secondPart) {
        for (let i = formattedNumber.length - 1; i > 0; i--) {
            if (formattedNumber[i] === '0') {
                formattedNumber = formattedNumber.substring(0, i)
            }else if (formattedNumber[i] === '.'){
                formattedNumber = formattedNumber.substring(0, i)
                break
            }else {
                break
            }
        }
    }

    return formattedNumber

    // if (Math.abs(float) < 0.000001) return float.toFixed(8)
    // if (Math.abs(float) < 0.01) return float.toFixed(point ? point : 6)
    // if (Math.abs(float) < 1) return float.toFixed(point ? point : 6)
    // if (Math.abs(float) < 1000) return float.toFixed(point ? point : 1)
    // return Math.round(float).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


export const deformatNumber = (str) => {
    try {
        let numParts = str.split('.')
        numParts[0] = numParts[0].replaceAll(",", "")
        return parseFloat(numParts.join("."))
    } catch (err) { return null }
}


export const p2e = (str) => {
    return str.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
}

export const deepCopy = (obj) => {
    if (!obj) return null
    return JSON.parse(JSON.stringify(obj))
}

export const separator = (input, seperater = ',', seperateLength = 3) => {
	if (input === null || input === undefined) return '';
	input = input.toString();
	let result = '';
	let count = 0;
	for (let i = input.length - 1; i > -1; i--) {
		if (count === seperateLength) {
			result = seperater + result;
			count = 0;
		}
		result = input.charAt(i) + result;
		count++;
	}
	return result;
};

const convertNumbers2English = function(string) {
    return string.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (c) => {
        return c.charCodeAt(0) - 1632;
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (c) => {
        return c.charCodeAt(0) - 1776;
    });
}

export const irMonth = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند']

export const formatDate = (date, type, lang) => {
    if (type === 'time') {
        const formatted = new Date(date).toLocaleTimeString(lang)
        return convertNumbers2English(formatted)
    }
    const formatted = new Date(date).toLocaleDateString(lang)
    return convertNumbers2English(formatted)
}

export const stringToNumber = (str) => {
    try {
        let numParts = str.split('.')
        numParts[0] = numParts[0].replaceAll(",", "")
        return numParts.join(".")
    } catch (err) { return null }
}
