import {enMessages} from "@/core/i18n/en";
import {faMessages} from "@/core/i18n/fa";

const messages = {
    en: enMessages,
    fa: faMessages
}

export const translator = (locale, tid) => {
    try {
        const value = messages[locale][tid]
        return value ? value : tid
    }
    catch (err) {
        return tid
    }
}
