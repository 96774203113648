export const routes = {
    home: '/',
    login: '/user/register-signin?tab=1',
    register: '/user/register-signin?tab=0',
    wallets: '/user/wallets',
    walletOperations: '/user/wallet-operations',
    addWalletsAndAccounts: '/user/accounts-cards',
    dashboard: '/user/dashboard',
    invite: '/user/invite',
    otcTrade: '/otc1/form',
    otcTrade2: '/otc2/form',
    otcTrade3: '/btc/',
    p2pTrade: '/trade/BTC_IRT',
    marketPage: (coin) => `/trade/${coin?.id?.toUpperCase()}_IRT`,
    marketTrade: '/market',
    about: '/about',
    contact: '/contact',
    news: 'news',
    guide: 'guide',
    conflict: 'conflict',
    blog: 'https://blog.kenzx.io/',
    faq: '/faq',
    userManual: '/user-manual',
    userManualDollorDigital: '/user-manual-dollor-digital',
    terms: '/terms',
    fees: '/fees',
    dictionary: '/dictionary',
    levels: '/levels',
    friends: '/friends',
    authorization: '/authorization',
    depositWithdraw: '/deposit-withdraw',
    instagram:
        'https://www.instagram.com/kenz_x_com?igsh=dzhnNjlkZHFnZjZ6&amp;utm_source=qr',
    twitter: 'https://x.com/Kenz_x_com?t=gEbu1k8cK4E-w1izTvBbcQ&amp;s=09',
    youtube: 'https://www.youtube.com/channel/UC1vBankKM-CM3uEAg3Tb8og',
    telegram: 'https://t.me/kenz_x_com',
    linkedin: 'https://www.linkedin.com/in/kenz-x-402776312/',
    invest: 'https://infinitycapital.ir/',
}
