import { createContext, useContext } from 'react'
import {usePricesSocket} from "@/core/hooks/socket/useSocketPrice";

const SocketContext = createContext()

export const useSocketContext = () => {
    return useContext(SocketContext)
}

export const SocketContextProvider = ({ children }) => {
    const { pricesList } = usePricesSocket()

    return <SocketContext.Provider value={{ pricesList }}>{children}</SocketContext.Provider>
}
