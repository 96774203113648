import { useContext, createContext, useState, useEffect } from 'react'
import { setCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { cookies } from 'next/headers'
import { useLogout } from '@/core/services/react-query/auth'

const context = createContext()

export const useMainContext = () => {
	return useContext(context)
}

const MainContextProvider = ({ children, theme: initialTheme, token: _token }) => {
	const [theme, _setTheme] = useState(initialTheme)
	const [token, _setToken] = useState()
	const [isOpen, _setIsOpen] = useState(false)

	const router = useRouter()
	const { pathname, asPath, query, locale } = router

	useEffect(() => {
		const storedToken = localStorage.getItem('token')
		if (storedToken) {
			_setToken(storedToken)
		}
	}, [])

	const toggleTheme = () => {
		const value = theme === 'dark' ? 'light' : 'dark'
		setCookie('theme', value)
		_setTheme(value)

		if (value === 'dark') document.documentElement.classList.add('dark')
		else document.documentElement.classList.remove('dark')
	}

	const toggleLang = () => {
		const lang = locale === 'en' ? 'fa' : 'en'
		router.push({ pathname, query }, asPath, { locale: lang })
		document.documentElement.setAttribute('dir', lang === 'en' ? 'ltr' : 'rtl')
		setCookie('lang', lang, { path: '/' })
	}

	const setToken = (value) => {
		setCookie('token', value)
		_setToken(value)
	}

	const setIsOpen = () => {
		_setIsOpen((prevState) => !prevState)
	}

	return (
		<context.Provider
			value={{
				theme,
				toggleTheme,
				toggleLang,
				token,
				setToken,
				setIsOpen,
				isOpen,
			}}
		>
			{children}
		</context.Provider>
	)
}

export default MainContextProvider
